import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import {BModal, BCard, BCardHeader, BCardBody, BButton, BDropdown, BDropdownItemButton, VBPopover} from 'bootstrap-vue'

import axios from 'axios'

import {replaceOrigin} from '~/assets/filters.js'

import Tracker from '~/plugins/tracker'
import Sampling from '~/plugins/sampling'

import ApiService from '~/services/ApiService'
import AgentsApiService from '~/services/AgentsApiService'

import {WidgetApi} from './enums'

import VueI18n from 'vue-i18n'
let services

export default function({
  token,
  client,
  clientId,
  channelId,
  apiUrl,
  apiType,
  ApiServiceConstructor,
  trackerOptions,
  samplingOptions,
  language,
}) {
  if (services) {
    return services
  }

  const headers = {}

  if (clientId || client) {
    headers.ClientId = clientId || client.id
  }

  headers.ChannelId = channelId

  if (token) {
    headers.Authorization = 'Bearer ' + token
  }
  // Axios
  const http = axios.create({
    // Get from config
    baseURL: apiUrl || process.env.API_URL || 'https://api.sp-selfservice.com/',
    headers,
  })

  // API service
  let apiService
  if (ApiServiceConstructor instanceof Function) {
    apiService = new ApiServiceConstructor({http})
  } else {
    switch (apiType) {
      case WidgetApi.Selfservice:
        apiService = new ApiService({http})
        break
      case WidgetApi.SpAgents:
        apiService = new AgentsApiService({http})
        break
      default:
        apiService = new ApiService({http})
    }
  }

  // plugin for GA and other type of tracking
  Vue.use(Tracker, trackerOptions)

  // plugin for speedperform sampling
  Vue.use(Sampling, samplingOptions)

  Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  })

  Vue.use(VueAwesomeSwiper)
  Vue.use(VueI18n)

  let locale = (client && client.locale) || 'en-GB'
  switch (language) {
    case 'fr':
      locale = 'fr-FR'
      break
    case 'en':
      locale = 'en-GB'
      break
  }

  const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'en-GB',
    silentTranslationWarn: true,
    messages: {
      'en-GB': require('./locales/en-GB.xml'),
      'da-DK': require('./locales/da-DK.xml'),
      'es-ES': require('./locales/es-419.xml'),
      'es-419': require('./locales/es-419.xml'),
      // 'de-DE': require('./locales/de-DE.xml'),
      // 'pt-PT': require('./locales/pt-PT.xml'),
      // 'pt-BR': require('./locales/pt-BR.xml'),
      'fr-FR': require('./locales/fr-FR.xml'),
      // 'it-IT': require('./locales/it-IT.xml'),
      // 'nl-NL': require('./locales/nl-NL.xml'),
      // 'cs-CZ': require('./locales/cs-CZ.xml'),
      'hu-HU': require('./locales/hu-HU.xml'),
    },
  })

  Vue.use(Vue => {
    Vue.prototype.$axios = http
    Vue.prototype.$api = apiService
    Vue.i18n = i18n
  })

  Vue.component('b-modal', BModal)
  Vue.component('b-card', BCard)
  Vue.component('b-card-header', BCardHeader)
  Vue.component('b-card-body', BCardBody)
  Vue.component('b-btn', BButton)
  Vue.component('b-dropdown', BDropdown)
  Vue.component('b-dropdown-item-button', BDropdownItemButton)

  Vue.directive('b-popover', VBPopover)
  const cdn = process.env.CDN_URL || 'https://cdn.sp-selfservice.com/'
  Vue.filter('cdn', url => replaceOrigin(url, cdn))

  services = {
    apiService,
    Vue,
    i18n,
  }

  return services
}
