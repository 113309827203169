export default class VueWidget {
  constructor (component) {
    this.component = component
  }

  render (selector) {
    this.component.$mount(selector)
  }

  destroy () {
    this.component.$destroy()
  }
}
