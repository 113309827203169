var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"range-slider-wrap"},[_c('div',{ref:"slider",staticClass:"range-slider",on:{"touchstart":_vm.startDrag}},[_c('div',{ref:"indicator",class:['indicator', _vm.x === 50 ? '' : _vm.x < 49 ? 'to-left' : 'to-right', {dragging: _vm.dragging}],style:({left: _vm.positionX}),on:{"mousedown":_vm.startDrag}}),_vm._v(" "),_c('div',{staticClass:"indicator-fill",class:_vm.x < 50 ? 'to-left' : 'to-right',style:({width: ((Math.abs(50 - _vm.x)) + "%")})}),_vm._v(" "),_c('div',{staticClass:"points-wrap"},_vm._l((_vm.points),function(point,index){return _c('div',{key:index + _vm._uid,class:['point',
                 {
                   'transparent': _vm.isTransparentClass(_vm.pointPositionX(index), _vm.x),
                   'to-left-middle-point':_vm.x < 50 && index === 2,
                   'to-right-middle-point':_vm.x > 50 && index === 2
                 }
        ],style:({
          width: (_vm.width + "%"),
          left: ((_vm.pointPositionX(index)) + "%")
        }),on:{"click":function($event){return _vm.onRangeClick(index)}}})}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }