<template>
  <b-modal
    :id="MODAL_ID"
    static
    size="lg"
    modal-class="sp-content-module bas-modal"
    hide-footer
    body-class="p-0"
    :title="(link && link.title) || ''"
  >
    <div v-if="link && link.urlActionType === 'Modal'">
      <iframe
        :src="link.url"
        class="border modal-iframe"
      />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'BasModal',
  data () {
    return {
      link: null,
      MODAL_ID: 'SpBASModal'
    }
  },
  mounted () {
    // EVENTS
    this.$root.$on('app::basModal::link', link => {
      this.link = link
      switch (link.urlActionType) {
      case 'NewWindow':
        window.open(link.url, link.title, 'width=640,height=480')
        break
      case 'NewTab':
        window.open(link.url, link.title)
        break
      case 'Modal':
        this.toggleModal(true)
        break
      }
    })
  },
  methods: {
    toggleModal (show) {
      if (show) {
        this.$root.$emit('bv::show::modal', this.MODAL_ID)
        this.results = []
      } else {
        this.$root.$emit('bv::hide::modal', this.MODAL_ID)
      }
    }
  }
}
</script>

<style lang="scss">
.bas-modal {
  .modal-content {
    padding: 0 !important;
  }

  .modal-iframe {
    width: 100%;
    height: 70vh;
  }
}
</style>
