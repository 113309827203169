import Enum from './Enum'

const ContentLangCode = {
  1: 'en-GB',
  2: 'da-DK',
  3: 'es-ES',
  4: 'cs-CZ',
  5: 'es-419',
  7: 'de-DE',
  8: 'it-IT',
  9: 'pt-PT',
  11: 'fr-FR',
  12: 'nl-NL',
  16: 'hu-HU',
  17: 'es-419',
  19: 'pt-BR',
}

Enum.makeEnum(ContentLangCode, ContentLangCode)

export default ContentLangCode
