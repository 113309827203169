import { Enum } from '~/enums'

const ButtonAction = {
  URL: 1,
  JS: 2,
  Next: 3,
  Prev: 4,
  Restart: 5
}

Enum.makeEnum(ButtonAction, ButtonAction)

export default ButtonAction
