import Enum from './Enum'

const ArticleType = {
  Article: 1,
  Form: 2,
  Link: 3,
  Diagnosticflow: 4,
  Promo: 5,
  Interactivenavigationmap: 6
}

Enum.makeEnum(ArticleType, ArticleType)

export default ArticleType
