<template>
  <div class="priority-step-block">
    <h1 class="title">
      {{ model.title }}
    </h1>

    <p class="description" v-html="model.description" />

    <div
      class="prioritizing-wrap"
      id="prioritizing-wrap"
    >
      <div
        v-for="(p, index) in priorities"
        :key="index"
        class="priority"
      >
        <div class="name">
          {{ p.text }}
        </div>
        <div class="range-wrap">
          <range-slider
            :min="1"
            :max="5"
            :step="1"
            :value.sync="p.rating"
          />
          <div class="labels">
            <span
              v-for="(label, index) in labels"
              :key="index"
            >{{ label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlider from '~/components/sp-advisor/RangeSlider.vue'

export default {
  components: { RangeSlider },
  props: {
    model: { type: Object, default: () => ({}) },
    values: { type: Array, default: () => [] },

    rangeLabels: { type: Array, default: () => [] }
  },
  data () {
    return {
      priorities: this.model.parameters.map(x => ({
        value: x.value,
        text: x.text,
        rating: this.getParameterRatingById(x.value)
      }))
    }
  },
  computed: {
    results () {
      return this.priorities.map(x => ({
        id: x.value,
        rating: x.rating
      }))
    },
    labels () {
      return this.rangeLabels.length > 0
        ? this.rangeLabels
        : [this.$t('advisor.label_less-important'), this.$t('advisor.label_more-important')]
    }
  },
  methods: {
    getParameterRatingById (parameterId) {
      var priority = this.values.find(x => x.id === parameterId)
      return (priority && priority.rating) || 3
    }
  },
  watch: {
    results (values) {
      this.$emit('update:values', values)
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .priority-step-block {
    .title {
    }

    .description {
    }

    .prioritizing-wrap {
      .priority {
        margin: 10px 0;
        display: flex;
        align-items: center;

        .name {
          width: 30%;
        }
        .range-wrap {
          width: 70%;

          .labels {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
