import {WidgetType} from '~/enums'
import ArticleWidget from '~/widgets/ArticleWidget.vue'
import AdvisorWidget from '~/widgets/AdvisorWidget.vue'
import VueWidget from '~/widgets/VueWidget'

export default class WidgetCreator {
  constructor({apiService, Vue, i18n}) {
    this.api = apiService
    this.Vue = Vue
    this.i18n = i18n
  }
  create(type, attrs, on) {
    const {api, Vue, i18n} = this
    switch (WidgetType[type]) {
      case WidgetType.Article: {
        const component = new Vue({
          render: h => h(ArticleWidget, {attrs, on}),
          i18n,
        })

        return new VueWidget(component)
      }
      case WidgetType.Advisor: {
        const component = new Vue({
          render: h => h(AdvisorWidget, {attrs, on}),
          i18n,
        })

        return new VueWidget(component)
      }
      default:
        return new Vue()
    }
  }
}
