<template>
  <b-modal
    static
    :id="MODAL_ID"
    size="lg"
    modal-class="sp-content-module"
    :dialog-class="`content-preview-modal uuidv-${uuidv4}`"
    hide-footer
    v-model="isModalShown"
    :title="modalTitle"
    title-tag="h3"
    title-class="text-center"
    ref="modal"
    @hide="hideLastContent"
  >
    <transition name="article-preview-fade">
      <div
        class="loader-wrap"
        v-if="isLoading || !isModalShown"
      >
        <sp-loader />
      </div>

      <!-- Article card view -->
      <template
        v-else-if="contentType === 'Article' && !isNotFound"
        :data-key="contentQueue.length"
      >
        <navigation-map
          v-if="
            contentItem.contentType === ArticleType.Interactivenavigationmap
          "
          ref="article"
          :content="contentItem.content"
          :content-id="contentItem.id"
          :revision-id="contentItem.headRevision && contentItem.headRevision.id"
          :language="contentItem.language"
          container=".modal-body"
        />
        <diagnostic-flow
          v-else-if="contentItem.contentType === ArticleType.Diagnosticflow"
          ref="article"
          :content="contentItem.content"
          :content-id="contentItem.id"
          :revision-id="contentItem.headRevision && contentItem.headRevision.id"
          :language="contentItem.language"
          :container="`.uuidv-${uuidv4} .modal-body`"
          :scroll-body="false"
        />
        <custom-article
          v-else
          ref="article"
          :content="contentItem.content"
          :content-id="contentItem.id"
          :revision-id="contentItem.headRevision && contentItem.headRevision.id"
          :language="contentItem.language"
          :container="`.uuidv-${uuidv4} .modal-body`"
          :scroll-body="false"
        />
      </template>

      <template v-else-if="contentType && !isNotFound">
        <!-- <problem v-if="contentType === 'Problem'" :item="contentItem" :device="device" @change-generic-item="loadGenericContent"></problem> -->
        <problem
          v-if="contentType === GenericContentType.Problem"
          :item="contentItem.content"
          :device="device"
          @changeProblem="loadGenericContent"
        />
        <guide
          v-else
          :guide="contentItem.content"
          no-header
        />
      </template>
    </transition>
  </b-modal>
</template>

<script>
import {
  Loader,
  CustomArticle,
  NavigationMap,
  DiagnosticFlow,
  CloseCross,
} from '~/components'

import SpLoader from '~/components/Loader'

import Guide from '~/components/guide/Guide'
import Problem from '~/components/ProblemView'
import { GenericContentType, ArticleType } from '~/enums'

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

function uuidv4() {
  return getRandomInt(9999)
}

export default {
  components: {
    SpLoader,
    CloseCross,
    CustomArticle,
    NavigationMap,
    DiagnosticFlow,
    Guide,
    Problem,
  },
  data() {
    return {
      contentQueue: [],
      searchDeviceRequest: {
        genericType: null,
        genericId: null,
        genericName: null,
      },
      device: null,
      isNotFound: false,
      isLoading: false,
      isModalShown: false,
      DEVICE_SEARCH_MODAL_ID: 'SpDeviceSearchModal',
      MODAL_ID: 'SpContentPreviewModal',
      GenericContentType,
      ArticleType,
      uuidv4: uuidv4(),
    }
  },
  computed: {
    modalTitle() {
      if (this.isLoading || !this.isModalShown) {
        return this.$t('article.loading')
      } else if (this.isNotFound) {
        if (this.contentType === 'Article') {
          return this.$t('errors.article_not_found')
        } else {
          const subject = (
            GenericContentType.getName(GenericContentType[this.contentType]) ||
            'content'
          ).toLowerCase()
          return this.$t(`errors.${subject}_not_found`)
        }
      } else if (this.contentType === 'Article') {
        return this.contentItem.seoTitle
      } else if (this.contentType) {
        // Guide and problem
        return this.contentItem.title
      }
    },

    contentItem() {
      return this.contentQueue[0]
    },
    contentType() {
      return this.contentItem && this.contentItem.type
    },
  },
  mounted() {
    // EVENTS
    // PREVIEW event
    this.$root.$on('app::contentPreviewModal::preview', this.onContentPreview)

    // DEVICE selected
    this.$root.$on(
      'app::deviceSearchModal::result',
      ({ device, genericContentReference }) => {
        this.device = device
        this.loadGenericContent(genericContentReference)
        // Open preview only after device search modal is closed
        this.$root.$once('bv::modal::hidden', (event, modalId) => {
          if (modalId === this.DEVICE_SEARCH_MODAL_ID) {
            this.toggleModal(true)
          }
        })
        this.$root.$emit('bv::hide::modal', this.DEVICE_SEARCH_MODAL_ID)
      },
    )

    // HIDDEN modals event
    this.$root.$on('bv::modal::hidden', (event, modalId) => {
      if (modalId === this.MODAL_ID) {
        this.isLoading = false
      }
    })

    // SHOWN modal event
    this.$root.$on('bv::modal::shown', (event, modalId) => {
      this.isModalShown = modalId === this.MODAL_ID
    })

    // Immitate BootstrapVue modal events
    // if (this.$modal) {
    //   const $modal = this.$modal('#' + this.MODAL_ID)
    //   $modal.on('shown.bs.modal', (e) => {
    //     this.$root.$emit('bv::modal::shown', e, this.MODAL_ID)
    //   })
    //   $modal.on('hidden.bs.modal', (e) => {
    //     this.$root.$emit('bv::modal::hidden', e, this.MODAL_ID)
    //   })

    //   this.$root.$on('bv::show::modal', (modalId) => {
    //     this.MODAL_ID === modalId && $modal.modal('show')
    //   })
    //   this.$root.$on('bv::hide::modal', (modalId) => {
    //     this.MODAL_ID === modalId && $modal.modal('hide')
    //   })
    // }
  },
  methods: {
    onContentPreview(previewData) {
      const item = previewData.item
      const parentId = previewData.parentId
      this.isNotFound = false
      const type = (item.contentType && 'Article') || item.type
      if (type === 'Article') {
        // TODO add error handler
        const resp = (
          parentId
            ? this.$api.getRelatedContentById(parentId, item.id)
            : this.$api.getContentById(item.id)
        )
          .then(
            (res) => {
              try {
                const response = res.data
                const content = JSON.parse(response.headRevision.content.Html)

                this.addContentToQueue({
                  type,
                  ...response,
                  content,
                })
                this.track('start')
              } catch (e) {
                console.error('Missing or unavailable article. ID:', item.id)
                this.isNotFound = true
              }
            },
            (e) => {
              this.isNotFound = true
              this.addContentToQueue()
            },
          )
          .finally(() => (this.isLoading = false))

        this.toggleModal(true)
        this.isLoading = true
      } else {
        this.cacheSearchDeviceRequest(
          type,
          item.combinedTopicId || item.id,
          item.name || '',
        )
        this.callSearchDeviceService()
        // const GENERIC_TYPES = ['Topic', 'Problem', 'Instruction', 'Article']
      }
    },
    addContentToQueue(contentItem) {
      this.contentQueue.unshift(contentItem)
      this.checkForNotFound()
    },
    removeContentFromQueue() {
      this.contentQueue.shift()
      this.isLoading = true
      this.$nextTick(() => {
        this.checkForNotFound()
        this.isLoading = false
      })
    },
    checkForNotFound() {
      if (this.contentType) {
        this.isNotFound = false
      } else {
        this.isNotFound = true
      }
    },
    changeDevice() {
      this.$root.$once('bv::modal::hidden', (event, modalId) => {
        if (modalId === this.MODAL_ID) {
          this.callSearchDeviceService()
          this.isLoading = true
        }
      })
      this.toggleModal(false)
    },
    callSearchDeviceService() {
      this.$root.$emit(
        'app::deviceSearchModal::search',
        this.searchDeviceRequest,
      )
    },
    toggleModal(show) {
      if (show) {
        this.$root.$emit('bv::show::modal', this.MODAL_ID)
      } else {
        this.removeContentFromQueue()
        if (!this.contentItem) {
          this.$root.$emit('bv::hide::modal', this.MODAL_ID)
        }
      }
    },
    hideLastContent(e) {
      if (this.contentItem.contentType === ArticleType.Diagnosticflow) {
        window.location.hash = ''
      }
      this.removeContentFromQueue()
      if (this.contentItem) {
        e.preventDefault()
      }
    },
    loadGenericContent({ genericType, genericId }) {
      const deviceId = this.device.id
      this.isLoading = true

      this.$api
        .getGenericContent({ deviceId, genericType, genericId })
        .then(
          (res) => {
            const response = res.data
            this.addContentToQueue({
              content: response,
              title: response.title,
              type: genericType,
            })
          },
          (e) => {
            this.isNotFound = true
            this.addContentToQueue({
              type: genericType,
            })
          },
        )
        .finally(() => {
          this.isLoading = false
        })
    },
    cacheSearchDeviceRequest(genericType, genericId, genericName) {
      this.searchDeviceRequest = {
        ...this.searchDeviceRequest,
        genericType,
        genericId,
        genericName,
      }
    },
    loadProblem(genericId) {
      this.loadGenericContent({ genericType: 'Problem', genericId })
    },
    track(eventName) {
      this.$emit('track', {
        type: 'pageview',
        title: this.contentItem.title,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .content-preview-modal {
    .change-device-wrap {
      margin-bottom: 1em;
    }
    .modal-body {
      &.content-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 300px;
      }
    }
    .loader-wrap {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .article-preview-fade-enter {
      opacity: 0;
    }
    .article-preview-fade-enter-active {
      transition: opacity 0.2s ease-out;
    }

    .article-preview-fade-enter-to {
      opacity: 1;
    }
  }
}
</style>
