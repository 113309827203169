import { ImageSize, ButtonAction } from '~/enums/content'

export let TYPE
;(function (TYPE) {
  TYPE.Text = 'Text'
  TYPE.RichText = 'RichText'
  TYPE.Image = 'Image'
  TYPE.Video = 'Video'
  TYPE.Group = 'Group'
  TYPE.Accordion = 'Accordion'
  TYPE.Collapse = 'Collapse'
  TYPE.TableText = 'TableText'
  TYPE.Table = 'Table'
  TYPE.Step = 'Step'
  TYPE.StepByStep = 'StepByStep'
  TYPE.Button = 'Button'
  TYPE.Html = 'Html'
})(TYPE || (TYPE = {}))

export let COMMAND
;(function (COMMAND) {
  COMMAND[(COMMAND.Create = 1)] = 'Create'
  COMMAND[(COMMAND.Copy = 2)] = 'Copy'
  COMMAND[(COMMAND.Trash = 3)] = 'Trash'
  COMMAND[(COMMAND.Index = 4)] = 'Index'
})(COMMAND || (COMMAND = {}))

export class Button {
  constructor(config) {
    this.label = config.label
    this.variant = config.variant
    this.action = config.action || {}
    this.target = config.target

    // to support old data models
    if (config.href) {
      this.action.type = ButtonAction.URL
      this.action.value = config.href
    }

    if (config.onClick) {
      this.action.type = ButtonAction.JS
      this.action.value = config.onClick
    }
  }
}

export class PropertyEditor {
  constructor(config) {
    this.editor = 'PropertyEditor'
    this.value = config && config.value
    this.options = (config && config.options) || {}
  }

  toJSON() {
    return { value: this.value, options: this.options }
  }
}
export class TextEditor extends PropertyEditor {
  constructor(config) {
    super(config)

    this.editor = 'TextEditor'
  }
}
export class LimitedTextEditor extends PropertyEditor {
  constructor(config) {
    super(config)

    const defaults = { maxLength: 100 }
    this.editor = 'LimitedTextEditor'
    this.options = { ...defaults, ...this.options }
  }
}
export class BoolEditor extends PropertyEditor {
  constructor(config) {
    super(config)

    const defaults = { text: 'On' }
    this.editor = 'BoolEditor'
    this.options = { ...defaults, ...this.options }
  }
}
export class FileEditor extends PropertyEditor {
  constructor(config) {
    super(config)

    const defaults = { url: '', name: '', title: '' }
    this.editor = 'FileEditor'
    this.options = { ...defaults, ...this.options }
  }
}
export class VideoEditor extends PropertyEditor {
  constructor(config) {
    super(config)

    const defaults = { id: '', url: '', title: '' }
    this.editor = 'VideoEditor'
    this.options = { ...defaults, ...this.options }
  }
}
export class EnumEditor extends PropertyEditor {
  constructor(config, _enumOptions) {
    super(config)

    this._enumOptions = _enumOptions || []
    this.editor = 'RadioEditor'
  }
}
export class AlignmentEditor extends EnumEditor {
  constructor(config) {
    const options = ['start', 'center', 'end', 'justify'].map((x) => ({
      tKey: `align-${x}`,
      value: x,
    }))
    super(config, options)

    this.editor = 'AlignmentEditor'
  }
}

export class ContentBlock {
  constructor(config) {
    // For indexing in an array
    if (config.isCopy) {
      this.originalKey = config.key
      this.key = Math.round(Math.random() * 1e32)
    } else if (config.key) {
      this.key = config.key
      this.originalKey = config.originalKey
    } else {
      this.key = Math.round(Math.random() * 1e32)
    }

    const props = config.props || {}
    this.props = {
      title: new TextEditor(props.title),
      description: new TextEditor(props.description),
      note: new TextEditor(props.note),
      caption: new TextEditor(
        props.caption || { options: { isMultiLine: true } },
      ),
    }
    this.typeName = config.typeName
    this.renderView = config.renderView

    this.icon = config.icon || 'file'
    this.hideHeader = !!config.hideHeader
    this.disabled = !!config.disabled
  }

  get type() {
    return this.typeName
  }

  toJSON() {
    const ignoredProps = [
      'icon',
      'hideHeader',
      'disabled',
      ...(this._ignoredProps || []),
    ]
    const out = {}
    for (const prop in this) {
      if (
        Object.prototype.hasOwnProperty.call(this, prop) &&
        ignoredProps.indexOf(prop) === -1 &&
        prop.charAt(0) !== '_'
      ) {
        out[prop] = this[prop]
      }
    }
    return out
  }

  generateIdFromKey() {
    return this.key && !isNaN(this.key)
      ? 'id_' + Math.round(this.key * 1e-16)
      : Math.round(Math.random() * 1e16)
  }
}
export class TextContentBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.html = config.html || ''
    this.typeName = TYPE.Text
    this.renderView = 'html'
    this.icon = 'textcolor'
  }
}
export class RichTextContentBlock extends TextContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.RichText
    this.icon = 'removeformatting'
  }
}
export class HtmlContentBlock extends TextContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Html
    this.icon = 'code-2'
  }
}
export class ImageContentBlock extends ContentBlock {
  constructor(config, name, url, mimeType) {
    super(config)

    this.typeName = TYPE.Image
    this.renderView = 'image'
    this.icon = 'picture'
    this.markers = config.markers || []
    this.markerActions = config.markerActions || []

    const props = config.props || {}
    const fileDefaults = {
      value: url,
      options: {
        url: url || '',
        name: name || '',
        title: 'file',
        displaySize: ImageSize.Original,
        mimeType: mimeType || 'image/png',
      },
    }

    this.props.file = new FileEditor({ ...fileDefaults, ...props.file })
  }
}
export class VideoContentBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Video
    this.renderView = 'video'
    this.icon = 'playcircle'
    const props = config.props || {}
    const videoDefaults = {
      options: {
        id: '',
        url: '',
        title: 'video-url',
        provider: '',
        thumbnail: '',
      },
    }
    this.props.video = new VideoEditor({ ...videoDefaults, ...props.video })
  }
}
export class GroupContentBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Group
    this.renderView = 'group'
    this.icon = 'folder'
    this.hasCollapse = true

    const name =
      config && config.root && !config.isCopy
        ? config.root.name
        : 'group-' + this.key
    const defaultRootConf = { name, isCopy: config && config.isCopy }
    const rootConf = { ...defaultRootConf, ...(config && config.root), name }
    this.root = new ContentBlockCollection(rootConf)
  }
}
export class AccordionContentBlock extends GroupContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Accordion
    this.renderView = 'accordion'
    this.icon = 'creditcard'
    this.hasCollapse = false

    if (this.root.isEmpty()) {
      this.root.append(new CollapseContentBlock({}))
    }
  }
}
export class CollapseContentBlock extends GroupContentBlock {
  constructor(config) {
    // Collapse isn't in the 'instrumentsBlocks' array in the custom-page.vue,
    // So we provide typeName, icon and title here
    super({
      title: 'collapse',
      icon: 'angelsdown',
      typeName: TYPE.Collapse,
      ...config,
    })

    this.typeName = TYPE.Collapse
    this.renderView = 'collapse'
    this.hideHeader = true
    this.hasCollapse = false

    const props = (config && config.props) || {}
    const isOpenedDefaults = {
      value: false,
      options: { title: 'init-state', text: 'opened', value: false },
    }
    this.props.isOpened = new BoolEditor({
      ...isOpenedDefaults,
      ...props.isOpened,
    })
    this.props.image = props.image && new FileEditor({ ...props.image })
  }
}
export class TableTextContentBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.html = config.html || ''
    this.typeName = TYPE.TableText
    this.renderView = 'html'
    this.icon = ''
    this.color = config.color || ''
    this.isActive = !!config.isActive

    this._ignoredProps = ['isActive']
  }
}
export class TableContentBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Table
    this.renderView = 'table'
    this.icon = 'grid'
    this.rows = (config && config.rows) || 3
    this.columns = (config && config.columns) || 3

    const textDefaults = {
      typeName: 'TableText',
      icon: 'textcolor',
      disabled: true,
    }
    const defaultColumnWidth = 200

    if (config && config.table) {
      this.table = config.table.map(
        (cell) =>
          new TableTextContentBlock(
            (cell && { ...cell, disabled: true }) || { ...textDefaults },
          ),
      )
      this.columnWidth = config && config.columnWidth
    } else {
      this.table = []
      for (let i = 0; i < this.rows * this.columns; i++) {
        this.table.push(new TableTextContentBlock({ ...textDefaults }))
      }

      this.columnWidth = []
      for (let i = 0; i < this.columns; i++) {
        this.columnWidth.push(defaultColumnWidth)
      }
    }
  }
}
export class StepContentBlock extends ContentBlock {
  constructor(config) {
    // Step isn't present in the 'instrumentsBlocks' array in the custom-page.vue,
    // So we provide typeName, icon and title here
    super({ title: 'Step', icon: 'skip', typeName: TYPE.Step, ...config })

    this.typeName = TYPE.Step
    this.renderView = 'step'
    const imageDefaults = {
      disabled: true,
      markerActions: ['', 'prev', 'next'],
    }
    const textDefaults = { typeName: 'Text', icon: 'textcolor', disabled: true }

    if (config && config.imageConfig) {
      const { name, url, promise } = config.imageConfig
      this.image = new ImageContentBlock({ ...config.image, ...imageDefaults }, name, url, promise)
    } else {
      this.image = new ImageContentBlock(
        (config && config.image && { ...config.image, ...imageDefaults }) ||
          imageDefaults,
      )
    }
    this.text = new TextContentBlock(
      (config && config.text && { ...config.text, disabled: true }) || {
        ...textDefaults,
      },
    )
  }
}
export class StepByStepContentBlock extends GroupContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.StepByStep
    this.renderView = 'stepByStep'
    this.icon = 'layers'
    this.hasCollapse = false
  }
}
export class ButtonsContentBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Button
    this.renderView = 'buttons'
    this.icon = 'link'
    this.customPropEditor = true

    this._ignoredProps = ['customPropEditor']
    this._actions = [
      {
        key: 'url',
        type: ButtonAction.URL,
        hasValue: true,
        placeholder: 'https://',
      },
      {
        key: 'js',
        type: ButtonAction.JS,
        hasValue: true,
        placeholder: 'doSomething();',
      },
    ]
    this._targetTypes = [
      { key: 'modal-window', type: 'Modal' },
      { key: 'new-tab', type: 'NewTab' },
      { key: 'new-window', type: 'NewWindow' },
    ]

    this.buttons = [...(config.buttons || [])].map((x) => new Button(x || {}))
    this.props.align = {
      value:
        (config.props && config.props.align && config.props.align.value) ||
        'start',
    }
  }
}

export class ContentBlockCollection {
  constructor(config) {
    if (config && config.factory && config.factory.create) {
      this.factory = config.factory
    } else {
      this.factory = new ContentBlockFactory()
    }

    this.contentBlocks =
      config && config.contentBlocks
        ? config.contentBlocks.map((b) => {
            return config && config.isCopy
              ? this.factory.copy(b)
              : this.factory.create(b.typeName, b)
          })
        : []

    this.name = config && config.name
  }

  toJSON() {
    return {
      contentBlocks: this.contentBlocks,
      name: this.name,
    }
  }

  isEmpty() {
    return this.contentBlocks.length === 0
  }

  getByIndex(index) {
    return this.contentBlocks[index]
  }

  getByKey(key) {
    return this.contentBlocks.find((x) => x.key === key)
  }

  getIndexOf(block) {
    return this.contentBlocks.indexOf(block)
  }

  append(contentBlock) {
    this.contentBlocks.push(contentBlock)
  }
}
export class ContentBlockFactory {
  create(typeName, config, isCopy) {
    switch (typeName) {
      case TYPE.Text:
        return new TextContentBlock({ typeName, ...config, isCopy })
      case TYPE.RichText:
        return new RichTextContentBlock({ typeName, ...config, isCopy })
      case TYPE.TableText:
        return new TableTextContentBlock({ typeName, ...config, isCopy })
      case TYPE.Html:
        return new HtmlContentBlock({ typeName, ...config, isCopy })
      case TYPE.Image:
        return new ImageContentBlock({ typeName, ...config, isCopy })
      case TYPE.Video:
        return new VideoContentBlock({ typeName, ...config, isCopy })
      case TYPE.Collapse:
        return new CollapseContentBlock({ typeName, ...config, isCopy })
      case TYPE.Step:
        return new StepContentBlock({ typeName, ...config, isCopy })
      case TYPE.Accordion:
        return new AccordionContentBlock({ typeName, ...config, isCopy })
      case TYPE.StepByStep:
        return new StepByStepContentBlock({ typeName, ...config, isCopy })
      case TYPE.Table:
        return new TableContentBlock({ typeName, ...config, isCopy })
      case TYPE.Button:
        return new ButtonsContentBlock({ typeName, ...config, isCopy })
      case TYPE.Group:
      default:
        return new GroupContentBlock({ typeName, ...config, isCopy })
    }
  }
}
