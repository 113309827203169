<template>
  <div
    :class="{ mobile }"
    class="comparison"
    ref="comparison"
  >
    <div class="devices">
      <div
        v-for="(d, index) in devices.slice(0, numbers)"
        :key="index"
        class="device-wrap"
      >
        <div class="image-wrap">
          <img :src="d.image" />
        </div>
        <div class="device-name">
          <h2>{{ d.name }}</h2>
        </div>
        <button
          :class="['btn-buy-device', { primary: index === 0 }]"
          v-if="clientName !== 'vfes' && d.eshopLink"
        >
          <a
            v-if="themeName === 'movistar' || themeName === 'movistargroup'"
            href="https://tienda.movistar.com.pe/celulares-liberados?utm_source=WP&utm_medium=ACCIONES&utm_campaign=PE_MOVIL_COL-DO-EQUIPOSLIBERADOS-B2C_2021-12-20_WEB_LDS-WEB_0_NA&utm_content=ACCIONES-RAPIDAS-HOME"
            class="buy-device-link"
            >{{ $t('advisor.buy_now') }}</a
          >
          <a
            v-else
            :href="getEShopLink(d.eshopLink)"
            target="_blank"
            class="buy-device-link"
          >
            {{
              themeName === 'movistarcl'
                ? $t('advisor.want_it')
                : $t('advisor.buy_now')
            }}
          </a>
        </button>
      </div>
    </div>
    <div class="specs-values">
      <Switcher
        v-if="themeName === 'movistarcl' && specs.length"
        :label="this.$t('advisor.label_show-differences')"
        @change="(value) => (showDiffSpecs = value)"
      />
      <div
        class="spec-group"
        v-for="(group, i) in specs"
        :key="i"
        v-show="
          !showDiffSpecs || (group.specs.some((el) => el.diff) && showDiffSpecs)
        "
      >
        <h4>{{ group.name }}</h4>
        <div
          v-for="(s, j) in group.specs"
          :key="j"
          class="group-specs"
          v-show="showDiffSpecs ? s.diff : true"
        >
          <div class="titles-cell">
            {{ s.name }}
          </div>
          <div
            class="value-cell"
            v-for="(v, k) in s.values"
            :key="k"
          >
            <span v-if="!v || v === 'empty'">-</span>
            <span v-else-if="v.value">{{ v.value }}</span>
            <span v-else>
              <span
                v-if="v.isSupported !== null"
                class="support-spec"
                >{{ $t('all.text_' + (v.isSupported ? 'yes' : 'no')) }}</span
              >
              <span v-else>-</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <RotateDeviceOverlay
      :text="'Para una mejor experiencia de visualización, gira tu dispositivo'"
      v-if="isDeviceOverlay"
    />
    <RotateDeviceOverlay v-else-if="themeName === 'vodafone'" />
  </div>
</template>

<script>
import RotateDeviceOverlay from '~/components/RotateDeviceOverlay'
import Switcher from '~/components/Switcher.vue'
import { _uniqBy } from '~/utils'

export default {
  components: {
    RotateDeviceOverlay,
    Switcher,
  },
  props: {
    devices: { type: Array, default: () => [] },
    numbers: { type: Number, default: 3 },
  },
  data() {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    const clientName = typeof CLIENT !== 'undefined' ? CLIENT.name : ''
    return {
      themeName: themeName || 'generic',
      clientName: clientName || '',
      mobile: window.innerWidth <= 1200,
      showDiffSpecs: false,
    }
  },
  computed: {
    specs() {
      if (this.devices.length === 0) return []
      return this.getSpecs()
    },
    isDeviceOverlay() {
      return this.hasTheme('movistargroup', 'movistar', 'movistarcl', 'three')
    },
  },
  mounted() {
    if (
      this.hasTheme(
        'movistargroup',
        'movistar',
        'movistarcl',
        'three',
        'vodafone',
      )
    ) {
      this.$refs.comparison.scrollIntoView({ behavior: 'smooth' })
    } else {
      const correction =
        this.themeName === 'ee'
          ? this.$refs.comparison.scrollHeight
          : this.$refs.comparison.scrollHeight - 350
      window.scroll({
        top: correction,
        behavior: 'smooth',
      })
    }
  },
  methods: {
    getSpecs() {
      let combinedDevices = []
      const specificationGroups = []
      this.devices.forEach((device) => {
        if (device == null) return
        combinedDevices = [
          ...combinedDevices,
          ...(device.specificationGroups || []),
        ]
      })
      combinedDevices.forEach((el) => {
        const duplicate = specificationGroups.find((x) => x.name === el.name)
        if (duplicate) {
          const combinedSpecs = _uniqBy(
            [...el.specifications, ...duplicate.specifications],
            'name',
          )
          duplicate.specifications = combinedSpecs
        } else {
          specificationGroups.push({ ...el })
        }
      })
      return specificationGroups.map((g) => ({
        name: g.name,
        specs: g.specifications.map((s) => {
          const values = this.devices
            .slice(0, this.numbers)
            .map((d) =>
              d ? this.getSpecForDevice(d.id, g.name, s.name) : 'empty',
            )
          return {
            name: s.name,
            values: values,
            diff: new Set(values.map((x) => x?.value)).size > 1,
          }
        }),
      }))
    },
    getSpecForDevice(id, groupName, specName) {
      const spec = this.devices.find((x) => x.id === id)
      if (spec) {
        const group = spec.specificationGroups.find((g) => g.name === groupName)
        return group && group.specifications.find((s) => s.name === specName)
      } else {
        return {}
      }
    },
    hasTheme(...themes) {
      return themes.includes(this.themeName)
    },
    getEShopLink(link) {
      if (this.themeName === 'movistarcl') {
        const url = new URL(link)
        !url.searchParams.has('utm_campaign') &&
          url.searchParams.append('utm_campaign', 'RecomendadorEquipos')
        !url.searchParams.has('utm_source') &&
          url.searchParams.append('utm_source', 'APP')
        !url.searchParams.has('utm_medium') &&
          url.searchParams.append('utm_medium', 'SPAdvisor')
        return url.toString()
      }
      return link
    },
  },
  created() {
    addEventListener('resize', () => {
      this.mobile = innerWidth <= 1200
    })
  },
}
</script>

<style lang="scss">
.comparison {
  .devices {
    display: flex;
    padding-left: 30%;
    .device-wrap {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-wrap {
        height: 120px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .btn-buy-device {
        display: none;
      }
    }
  }
  .specs-values {
    .spec-group {
      margin: 15px 0;

      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
      }

      .group-specs {
        display: flex;

        .titles-cell,
        .value-cell {
          font-size: 12px;
          line-height: 14px;
        }

        .titles-cell {
          width: 30%;
        }
        .value-cell {
          width: 23.33%;
        }
      }
    }
  }
}
</style>
