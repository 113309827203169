<template>
  <div
    v-if="!isLoading"
    ref="grouped-slider"
    class="grouped-slider-view"
    :style="{margin: `0px ${-baseMargin}px`}"
  >
    <swiper :options="baseSwiper.options">
      <swiper-slide
        v-for="(slide, i) in groupedSteps"
        :key="i"
        :style="`max-width: ${baseSwiper.settings.slideMaxWidth}px`"
      >
        <div class="swiper-slide-container">
          <div
            class="card"
            v-for="(step, n) in slide"
            :key="n"
            :class="{'mx-auto': steps.length === 1}"
          >
            <div class="card-body">
              <template v-if="step.image.props.file.value">
                <div class="markers-wrap show-image sp-b-img">
                  <magnifier
                    :glass-size="200"
                    :glass-style="{}"
                    :zoom-level="3"
                    :padding="0"
                  >
                    <template #content>
                      <span
                        :class="
                          'img-wrap image-' +
                            ((step.image.props.file.options.displaySize &&
                              step.image.props.file.options.displaySize.toLowerCase()) ||
                              'original')
                        "
                      >
                        <img
                          :src="step.image.props.file.value | cdn"
                          :alt="step.image.props.file.options.name"
                        >
                        <sp-image-marker
                          v-for="(marker, idx) in step.image.markers"
                          :key="idx"
                          :data="marker"
                          @click="navigateSlide('sp-swiper-' + b.key.toString(16), marker.action)"
                        />
                      </span>
                    </template>
                  </magnifier>
                </div>
              </template>
            </div>
            <div class="card-footer">
              <div
                class="stepper-wrap"
                v-if="steps.length > 1"
              >
                <span class="current-step">{{ 2 * i + n + 1 }}</span>
                <span>/</span>
                <span>{{ steps.length }}</span>
              </div>
              <h4 v-html="step.props.title.value" />
              <div v-html="trimNonBrakingSpaces(step.text.html)" />
            </div>
          </div>
        </div>
      </swiper-slide>
      <div
        v-if="steps.length > 1"
        class="swiper-pagination"
        slot="pagination"
      />
      <button
        type="button"
        class="swiper-button-prev border-0"
        slot="button-prev"
        :style="`width: ${baseSwiper.settings.navArrowWidth}px`"
      />
      <button
        type="button"
        class="swiper-button-next border-0"
        slot="button-next"
        :style="`width: ${baseSwiper.settings.navArrowWidth}px`"
      />
    </swiper>
  </div>
</template>

<script>
import SpImageMarker from '../ImageMarker.vue'
import Magnifier from '../magnifier/Magnifier.vue'

export default {
  props: ['steps'],
  components: {
    SpImageMarker,
    Magnifier
  },
  data () {
    return {
      isLoading: true,
      baseSwiper: {
        options: {
          slidesPerView: 'auto',
          spaceBetween: 20,
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true,
            dynamicMainBullets: 1,
            clickable: true
          },
          keyboard: {
            enabled: true,
            onlyInViewport: false
          },
          a11y: {
            enabled: false
          },
          on: {
            reachEnd: () => {
              // Mark (.path-item) process element that SbS process finished
              const process = this.$refs['grouped-slider'].closest('.path-item')
              if (process !== null) {
                process.classList.add('sbs--process-finished')
              }
            }
          }
        },
        settings: {
          reset: false,
          slideMaxWidth: 300,
          navArrowWidth: 0
        }
      },
      baseMargin: 0
    }
  },
  mounted () {
    setTimeout(() => {
      this.setupSwiperSettings()
      this.isLoading = false
    }, 500)

    window.addEventListener('resize', this.setupSwiperSettings)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setupSwiperSettings)
  },
  computed: {
    groupedSteps () {
      return this.steps.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    }
  },
  methods: {
    setupSwiperSettings () {
      if (document.querySelector('#speedperform')) {
        var topicTitleWidth = document.querySelector('.sp-b-steps').offsetWidth - 40
        this.baseSwiper.settings = {
          reset: true,
          slideMaxWidth: topicTitleWidth,
          navArrowWidth: (document.querySelector('#speedperform').offsetWidth - topicTitleWidth - 60) / 2
        }

        this.baseMargin = (document.querySelector('#speedperform').offsetWidth - topicTitleWidth) / 2 - 20
      }
    },
    trimNonBrakingSpaces (str) {
      if (!str) return ''
      return str.replace('&nbsp;', ' ')
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins';
@include sp-content-module {
  .grouped-slider-view {
    .swiper-slide {
      .swiper-slide-container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        .card {
          width: calc(50% - 10px);
        }
      }
    }

    .card {
      height: 100%;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

      .card-body {
        padding: 0;
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card-footer {
        padding-bottom: 20px;
        border: none;
        text-align: left;
        background-color: transparent;
        border-top: 1px solid rgba(0, 0, 0, 0.25);

        .stepper-wrap {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
