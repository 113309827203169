<template>
  <div class="range-slider-wrap">
    <div
      ref="slider"
      class="range-slider"
      @touchstart="startDrag"
    >
      <div
        ref="indicator"
        :class="['indicator', x === 50 ? '' : x < 49 ? 'to-left' : 'to-right', {dragging: dragging}]"
        :style="{left: positionX}"
        @mousedown="startDrag"
      />
      <div
        class="indicator-fill"
        :class="x < 50 ? 'to-left' : 'to-right'"
        :style="{width: `${Math.abs(50 - x)}%`}"
      />

      <div class="points-wrap">
        <div
          v-for="(point, index) in points"
          :key="index + _uid"
          :class="['point',
                   {
                     'transparent': isTransparentClass(pointPositionX(index), x),
                     'to-left-middle-point':x < 50 && index === 2,
                     'to-right-middle-point':x > 50 && index === 2
                   }
          ]"
          :style="{
            width: `${width}%`,
            left: `${pointPositionX(index)}%`
          }"
          @click="onRangeClick(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, required: true },
    value: { type: Number, required: true }
  },
  data () {
    const stepsCount = (this.max - this.min) / this.step
    const points = [...Array(stepsCount + 1).keys()]
    const pointsValues = points.map(x => this.min + this.step * x)
    const initialPointIndex = pointsValues.indexOf(this.value)
    const stepsDimensionsInPercentage = points.map(x => (100 / stepsCount) * x)

    return {
      dragging: false,
      points,
      pointsValues,
      stepsDimensionsInPercentage,
      x: stepsDimensionsInPercentage[initialPointIndex],
      width: 100 / (points.length - 1),
      indicatorDimensions: {},
      sliderDimensions: {}
    }
  },
  mounted () {
    window.addEventListener('mouseup', this.stopDrag)
    window.addEventListener('touchend', this.stopDrag)
    window.addEventListener('touchcancel', this.stopDrag)
    this.setBaseDimensions()
    window.addEventListener('resize', this.setBaseDimensions)

    window.addEventListener('touchmove', this.doDrag)
    window.addEventListener('mousemove', this.doDrag)
  },
  beforeDestroy () {
    window.removeEventListener('mouseup', this.stopDrag)
    window.addEventListener('touchend', this.stopDrag)
    window.addEventListener('touchcancel', this.stopDrag)
    window.removeEventListener('resize', this.setBaseDimensions)
  },
  computed: {
    positionX () {
      const { width } = this.indicatorDimensions

      const w = (width * 100) / this.sliderDimensions.width / 2

      if (this.dragging) {
        if (this.x > 100 - w) {
          this.x = 100 - w
        } else if (this.x - w < 0) {
          this.x = w
        }
      }

      let out = ''
      switch (this.x) {
      case 0:
        out = '0px'
        break
      case 100:
        out = `calc(${this.x}% - ${width}px)` // Indicator size 16px
        break
      default:
        out = `calc(${this.x}% - ${width / 2}px)`
      }

      return out
    }
  },
  methods: {
    setBaseDimensions () {
      const indicator = this.$refs.indicator
      this.indicatorDimensions = indicator ? indicator.getBoundingClientRect() : {}
      const slider = this.$refs.slider
      this.sliderDimensions = slider ? slider.getBoundingClientRect() : {}
    },
    isTransparentClass (pointPositionX, x) {
      return (pointPositionX > 50 ? x >= 74 : x <= 26) && pointPositionX !== 37.5
    },
    pointPositionX (index) {
      return this.width * index - this.width / 2
    },
    startDrag (e) {
      // e.preventDefault()
      this.dragging = true
    },
    stopDrag (e) {
      this.dragging = false
      const diff = this.stepsDimensionsInPercentage[1] - this.stepsDimensionsInPercentage[0]
      this.stepsDimensionsInPercentage.forEach((x, index) => {
        if (Math.abs(x - this.x) <= diff / 2) {
          this.x = x
          this.$emit('update:value', this.pointsValues[index])
        }
      })
    },
    doDrag (e) {
      // hide e.preventDefault() to avoid errors on mobile devices touch events
      // e.preventDefault()

      // Get some additional screen configs that affect on calculation (base for: EE UK Table)
      const scale = parseFloat(window.localStorage.getItem('scale')) || 1
      const rotated = window.localStorage.getItem('rotated') === 'true'

      const clientX = e.clientX || e.targetTouches[0].clientX
      if (this.dragging) {
        const wrap = document.querySelector('.range-slider-wrap > .range-slider')
        const wrapDimensions = wrap.getBoundingClientRect()
        if (!rotated) {
          this.x = (100 * (clientX - scale * wrapDimensions.x)) / (scale * wrapDimensions.width)
        } else {
          this.x = 100 - (100 * (clientX - scale * wrapDimensions.x)) / (scale * wrapDimensions.width)
        }
      }
    },
    onRangeClick (index) {
      this.x = this.stepsDimensionsInPercentage[index]
      this.$emit('update:value', this.pointsValues[index])
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .range-slider-wrap {
    overflow: hidden;
    padding: 8px 0;

    .range-slider {
      position: relative;
      height: 8px;
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: rgba($color: $dark, $alpha: 0.1);
      border-radius: 8px;

      .indicator {
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 8px;
        z-index: 3;
        background-color: $dark;
        cursor: pointer;
      }

      .indicator-fill {
        position: absolute;
        height: 100%;
        z-index: 1;
        background-color: rgba($color: $dark, $alpha: 0.25);

        &.to-left {
          right: 50%;
          border-top-left-radius: 13px;
          border-bottom-left-radius: 13px;
        }
        &.to-right {
          left: 50%;
          border-top-right-radius: 13px;
          border-bottom-right-radius: 13px;
        }
      }

      .points-wrap {
        height: 100%;
        width: 100%;
      }

      .point {
        position: absolute;
        z-index: 2;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
</style>
