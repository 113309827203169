<template>
  <content-module>
    <div class="guide">
      <div class="guide-view-switcher justify-content-end">
        <!-- <button class="btn-icon mx-1 my-1" @click="switchView(GUIDE_VIEW.List)"
                  :pressed="view === GUIDE_VIEW.List">
          <span class="ss-list" :class="{active: view === GUIDE_VIEW.List }"></span>
        </button>
        <button class="btn-icon my-1" @click="switchView(GUIDE_VIEW.Slider)"
                  :pressed="view === GUIDE_VIEW.Slider">
          <span class="ss-picture" :class="{active: view === GUIDE_VIEW.Slider}"></span>
        </button> -->
        <steps-view-switcher :state.sync="view" />
      </div>
      <h2 v-if="showHeader">
        {{ guide.title }}
      </h2>
      <p
        v-if="showHeader"
        v-html="guide.description"
        @click="checkForInternalLink"
        class="description"
      />
      <div
        class="slider-view"
        v-if="view === GUIDE_VIEW.Slider"
      >
        <guide-slider-view
          :guide="guide"
          :device="device"
        />
      </div>
      <div
        v-else
        class="list-view text-left"
      >
        <div class="d-flex justify-content-center">
          <div class="w-75">
            <guide-list-view
              :guide="guide"
              :device="device"
            />
          </div>
        </div>
      </div>
    </div>
  </content-module>
</template>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .instruction-description {
    font-size: 16px;
  }

  .wm-hl-highlight {
    // Just a workaround when the image is too large (content issue)
    max-width: 100%;
  }
  .guide {
    text-align: center;
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;

    .description {
      a.internal {
        color: $link-color;
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import { ContentModule, CloseCross } from '~/components'
import GuideSliderView from './GuideSliderView'
import GuideListView from './GuideListView'

import StepsViewSwitcher from '~/components/StepsViewSwitcher'
import _ from 'underscore'
export default {
  props: ['guide', 'device', 'noHeader'],
  components: {
    GuideSliderView,
    GuideListView,
    CloseCross,
    ContentModule,
    StepsViewSwitcher,
  },
  data() {
    return {
      view: 'Slider',
      swiperOptions: {
        // 4.x version
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
      GUIDE_VIEW: {
        Slider: 'Slider',
        List: 'List',
      },
    }
  },
  computed: {
    steps() {
      return _.flatten(
        this.guide.blocks.map((b) =>
          b.steps.map((s) => ({ title: b.title, ...s })),
        ),
      )
    },
    showHeader() {
      return typeof this.noHeader === 'undefined'
    },
  },
  methods: {
    switchView(view) {
      this.view = view
    },
    checkForInternalLink(e) {
      const target = e.target
      if (
        target.tagName.toLowerCase() === 'a' &&
        target.classList.contains('internal')
      ) {
        const topicId = target.getAttribute('data-topic-id')
        const guideId = target.getAttribute('data-guide-id')
        if (!topicId && !guideId) {
          this.notifierNotify({
            type: 'error',
            title: this.$t('notification.title_error'),
            message: this.$t('device.title_no-guides'),
          })
        }

        let topics, guide
        const params = {}

        if (topicId) {
          params.topicId = topicId
          topics = [
            this.$store.state.dashboard.deviceContent.topics.find(
              (x) => x.id === topicId,
            ),
          ]
        } else {
          topics = this.$store.state.dashboard.deviceContent.topics || []
        }
        guide = _.flatten(
          topics.map((x) =>
            _.flatten(
              x.chapters.map((y) =>
                y.guides.map((g) => ({ ...g, topicId: x.id })),
              ),
            ),
          ),
        ).find((g) => g.id === guideId)

        this.$emit('internal-click', { guide, params })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
