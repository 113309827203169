<template>
  <div
    v-if="show"
    class="loader-overlay"
  />
</template>

<script>
export default {
  name: 'LoaderOverlay',
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    this.$root.$on('app::loader-overlay::toggle', (value) => this.toggle(value))
  },
  methods: {
    toggle(state) {
      this.show = state
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .loader-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    opacity: 0.75;

    &::after {
      content: 'Loading';
    }
  }
}
</style>
