<template>
  <div
    role="group"
    aria-label="Star rating"
    class="star-rating"
    :style="{ pointerEvents: disableClick ? 'none' : 'auto' }"
    @mouseleave="setHoverIndex(0)"
  >
    <div
      v-for="i in numberOfStars"
      :key="i"
    >
      <div
        role="radio"
        tabindex="0"
        :aria-label="starLabel[i]"
        :aria-checked="rating === i"
        class="star"
        :class="{ hovered: i <= hoverIndex, selected: i <= rating }"
        @keydown.enter="adjustRating(i)"
        @mouseover="setHoverIndex(i)"
        @click="adjustRating(i)"
      >
        Star {{ i }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rating: { type: Number, default: 0 },
    numberOfStars: { type: Number, default: 5 },
    disableClick: { type: Boolean, default: false },
  },
  data() {
    return {
      ratingValue: this.rating,
      hoverIndex: 0,
      starLabel: {
        1: 'Poor',
        2: 'Fair',
        3: 'Good',
        4: 'Great',
        5: 'Excellent',
      },
    }
  },
  methods: {
    adjustRating(index) {
      if (this.disableClick) return

      if (index !== undefined) {
        this.ratingValue = index
        this.$emit('update:rating', this.ratingValue)
        this.$emit('change', this.ratingValue)
      }
    },
    setHoverIndex(index) {
      this.hoverIndex = index
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';

@include sp-content-module {
  .star-rating {
    display: inline-flex;
    gap: 16px;

    > div {
      display: inline-flex;
    }

    .star {
      cursor: pointer;
    }
  }
}
</style>
