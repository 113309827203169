var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp-b-steps"},[_c('steps-view-switcher',{attrs:{"state":_vm.stepsView},on:{"update:state":function($event){_vm.stepsView=$event}}}),_vm._v(" "),_c('div',{staticClass:"sp-b-steps-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepsView === 'Slider'),expression:"stepsView === 'Slider'"}]},[_c('div',{ref:"gallery-slider",staticClass:"swiper-container gallery-container",attrs:{"data-swiper-id":'sp-swiper-' + _vm.b.key.toString(16)}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.b.root.contentBlocks),function(step,index){return _c('div',{key:index,staticClass:"swiper-slide sp-b-step"},[_c('div',{staticClass:"slider-step-highlight"},[(step.image.props.file.value)?[_c('div',{staticClass:"markers-wrap show-image sp-b-img"},[_c('span',{class:'img-wrap image-' +
                      ((step.image.props.file.options.displaySize &&
                        step.image.props.file.options.displaySize.toLowerCase()) ||
                        'original')},[_c('img',{attrs:{"src":_vm._f("cdn")(step.image.props.file.value),"alt":step.image.props.file.options.name}}),_vm._v(" "),_vm._l((step.image.markers),function(marker,idx){return _c('sp-image-marker',{key:idx,attrs:{"data":marker},on:{"click":function($event){_vm.navigateSlide(
                          'sp-swiper-' + _vm.b.key.toString(16),
                          marker.action
                        )}}})})],2),_vm._v(" "),_c('magnifier',{attrs:{"glass-size":300,"glass-style":{
                      border: '1px solid #1a1a1a',
                      borderRadius: '50%',
                    },"zoom-level":3,"padding":25},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{class:'img-wrap image-' +
                          ((step.image.props.file.options.displaySize &&
                            step.image.props.file.options.displaySize.toLowerCase()) ||
                            'original')},[_c('img',{attrs:{"src":_vm._f("cdn")(step.image.props.file.value),"alt":step.image.props.file.options.name}}),_vm._v(" "),_vm._l((step.image.markers),function(marker,idx){return _c('sp-image-marker',{key:idx,attrs:{"data":marker},on:{"click":function($event){_vm.navigateSlide(
                              'sp-swiper-' + _vm.b.key.toString(16),
                              marker.action
                            )}}})})],2)]},proxy:true}],null,true)})],1)]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"sp-b-steps-caption"},[_c('span',{staticClass:"d-block text-center steps-counter"},[_vm._v(_vm._s(_vm.$t('guide.step_x_of_x', {
                  current: index + 1,
                  total: _vm.b.root.contentBlocks.length,
                })))]),_vm._v(" "),_c('span',{staticClass:"alt-steps-counter"},[_c('span',{staticClass:"current-step"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('span',{staticClass:"step-divider"},[_vm._v("/")]),_vm._v(" "),_c('span',{staticClass:"total-steps"},[_vm._v(_vm._s(_vm.b.root.contentBlocks.length))])]),_vm._v(" "),_c('span',{staticClass:"alt-steps-counter-2"},[_c('span',{staticClass:"current-step"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('span',{staticClass:"step-divider"},[_vm._v("of")]),_vm._v(" "),_c('span',{staticClass:"total-steps"},[_vm._v(_vm._s(_vm.b.root.contentBlocks.length))])]),_vm._v(" "),_c('h4',{staticClass:"step-by-step-title",domProps:{"innerHTML":_vm._s(step.props.title.value)}}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.trimNonBrakingSpaces(step.text.html))}})])])}),0),_vm._v(" "),(_vm.showSwiperPagination)?_c('div',{staticClass:"swiper-pagination"},[_c('span',{staticClass:"active"}),_vm._v(" "),_c('span',{staticClass:"total"})]):_vm._e(),_vm._v(" "),(_vm.showSwiperPagination)?_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}):_vm._e(),_vm._v(" "),(_vm.showSwiperPagination)?_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"sp-b-steps-caption-static",class:[
          { 'has-pagination': _vm.showSwiperPagination },
          { 'has-title': _vm.containTitle } ]},[(_vm.showSwiperPagination)?_c('span',{staticClass:"d-block text-center steps-counter"},[_vm._v(_vm._s(_vm.$t('guide.step_x_of_x', {
              current: _vm.index + 1,
              total: _vm.b.root.contentBlocks.length,
            })))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"alt-steps-counter"},[_c('span',{staticClass:"current-step"},[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" "),_c('span',{staticClass:"step-divider"},[_vm._v("/")]),_vm._v(" "),_c('span',{staticClass:"total-steps"},[_vm._v(_vm._s(_vm.b.root.contentBlocks.length))])]),_vm._v(" "),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.indexStep.props.title.value),expression:"indexStep.props.title.value"}],staticClass:"step-by-step-title",domProps:{"innerHTML":_vm._s(_vm.indexStep.props.title.value)}}),_vm._v(" "),_c('div',{staticClass:"step-text",domProps:{"innerHTML":_vm._s(_vm.trimNonBrakingSpaces(_vm.indexStep.text.html))}})]),_vm._v(" "),_c('div',{staticClass:"grouped-gallery-container"},[_c('grouped-slider-view',{attrs:{"steps":_vm.b.root.contentBlocks}})],1)]),_vm._v(" "),(_vm.stepsView === 'List')?_c('ol',{staticClass:"list-container"},_vm._l((_vm.b.root.contentBlocks),function(step,index){return _c('li',{key:index,staticClass:"sp-b-step-list"},[_c('div',{staticClass:"sp-b-html"},[_c('h4',{directives:[{name:"show",rawName:"v-show",value:(step.props.title.value),expression:"step.props.title.value"}],domProps:{"innerHTML":_vm._s(step.props.title.value)}}),_vm._v(" "),_c('div',{staticClass:"step-text",domProps:{"innerHTML":_vm._s(_vm.trimNonBrakingSpaces(step.text.html))}})]),_vm._v(" "),_c('div',{staticClass:"step-image"},[(step.image.props.file.value)?[_c('div',{staticClass:"markers-wrap sp-b-img"},[_c('span',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":_vm._f("cdn")(step.image.props.file.value),"alt":step.image.props.file.options.name}}),_vm._v(" "),_vm._l((step.image.markers),function(marker,idx){return _c('sp-image-marker',{key:idx,attrs:{"data":marker},on:{"click":function($event){_vm.navigateSlide(
                      'sp-swiper-' + _vm.b.key.toString(16),
                      marker.action
                    )}}})})],2),_vm._v(" "),_c('magnifier',{attrs:{"glass-size":80,"glass-style":{
                  border: '1px solid #1a1a1a',
                  borderRadius: '50%',
                },"zoom-level":3,"padding":10},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":_vm._f("cdn")(step.image.props.file.value),"alt":step.image.props.file.options.name}}),_vm._v(" "),_vm._l((step.image.markers),function(marker,idx){return _c('sp-image-marker',{key:idx,attrs:{"data":marker},on:{"click":function($event){_vm.navigateSlide(
                          'sp-swiper-' + _vm.b.key.toString(16),
                          marker.action
                        )}}})})],2)]},proxy:true}],null,true)})],1)]:_vm._e()],2)])}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }