import { Enum } from '~/enums'

const ImageSize = {
  Small: 'Small',
  Large: 'Large',
  Original: 'Original'
}

Enum.makeEnum(ImageSize, ImageSize)

export default ImageSize
