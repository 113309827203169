<template>
  <div class="steps-view-switch-wrap">
    <div class="steps-view-switch">
      <label :class="{ active: stepsView === 'List' }">
        <span class="ss ss-list" />
        <input
          type="radio"
          name="steps"
          value="List"
          v-model="stepsView"
        >
      </label>
      <label :class="{ active: stepsView === 'Slider' }">
        <span class="ss ss-picture" />
        <input
          type="radio"
          name="steps"
          value="Slider"
          v-model="stepsView"
        >
      </label>
    </div>
    <div class="steps-view-switch-2">
      <label
        v-if="stepsView !== 'List'"
        class="m-0 p-0"
      >
        <span class="name">List view</span>
        <span class="ss ss-list" />
        <input
          type="radio"
          name="steps"
          value="List"
          v-model="stepsView"
        >
      </label>
      <label
        v-else
        class="m-0 p-0"
      >
        <span class="name">Gallery view</span>
        <span class="ss ss-picture" />
        <input
          type="radio"
          name="steps"
          value="Slider"
          v-model="stepsView"
        >
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ['state'],
  computed: {
    stepsView: {
      get () {
        return this.state
      },
      set (value) {
        this.$emit('update:state', value)
      },
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .steps-view-switch {
    display: flex;
    justify-content: flex-end;

    label {
      font-size: 16px;
      margin: 0 0.25em;
      display: block;
      width: 32px;
      height: 32px;
      cursor: pointer;
      text-align: center;
      line-height: 31px;

      &.active {
        background-color: $light-gray-color;
        color: #999;
      }
      input {
        display: none;
      }
    }
  }

  .steps-view-switch-2 {
    display: none;

    label {
      display: block;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      cursor: pointer;

      .name {
        text-decoration: underline;
      }

      input {
        display: none;
      }
    }
  }
}
</style>
