<template>
  <b-modal
    :id="MODAL_ID"
    static
    size="lg"
    modal-class="sp-content-module advisor-article-modal"
    hide-footer
    body-class="p-0"
    :title="title"
    @hide="close"
  >
    <div
      ref="widget"
      class="advisor-article"
      v-if="content"
    >
      <div v-if="!isLoading">
        <template v-if="!isNotFound && content">
          <div
            :is="type"
            :content="content.html"
            :content-id="content.id"
            :revision-id="content.revisionId"
            :language="content.language"
            v-bind="options"
            @track="track"
          />
        </template>
        <template v-else>
          <p>{{ $t('errors.article_not_found') }}</p>
        </template>
      </div>
      <sp-loader
        v-else
        class="widget-loader"
      />

      <content-preview-modal @track="track" />
      <device-search-modal v-bind="options" />
      <BASModal />
    </div>
  </b-modal>
</template>
<script>
import {
  CustomArticle,
  DiagnosticFlow,
  NavigationMap,
  ContentPreviewModal,
  DeviceSearchModal,
  BASModal,
  Loader
} from '~/components'
import { ArticleType } from '~/enums'

export default {
  inheritAttrs: false,
  components: {
    CustomArticle,
    DiagnosticFlow,
    NavigationMap,
    ContentPreviewModal,
    DeviceSearchModal,
    BASModal,
    SpLoader: Loader
  },
  props: ['contentId'],
  data () {
    return {
      content: null,
      isNotFound: false,
      isLoading: true,
      width: 0,
      MODAL_ID: 'SpAdvisorArticlePreviewModal',
    }
  },
  computed: {
    type () {
      // TODO make a better mapping
      const articleTypeComponents = {
        [ArticleType.Article]: 'custom-article',
        [ArticleType.Diagnosticflow]: 'diagnostic-flow',
        [ArticleType.Interactivenavigationmap]: 'navigation-map'
      }

      return this.content && articleTypeComponents[ArticleType[this.content.type]]
    },
    title () {
      return this.content && this.content.title
    }
  },
  async mounted () {
    this.width = this.$refs.widget?.offsetWidth
    this.fetchArticle()
  },
  provide () {
    return {
      $modal: window.wmjQuery || (() => {}),
      getContainerWidth: () => {
        return this.width
      },
      getContainerBreakpoint: () => {
        const bps = {
          xs: 0,
          sm: 540,
          md: 720,
          lg: 960,
          xl: 1140
        }

        const width = this.width || 0

        const breakpoint = Object.keys(bps)
          .reverse()
          .find(key => width >= bps[key])
        return breakpoint
      }
    }
  },
  methods: {
    track ({ type, title, action, category, label }) {
      if (this.$sampling) {
        switch (type) {
          case 'pageview':
            this.$sampling.page({ title })
            break
          case 'event':
            this.$sampling.event({
              action,
              category: category || this.content.title,
              label
            })
        }
      }
    },
    close () {
      this.$emit('close')
      window.location.hash = ''
      this.content = null
    },
    async fetchArticle () {
      if (this.content) {
        this.isLoading = false
        this.$emit('loaded', this.content)
      } else if (this.contentId || this.contentSlug) {
        try {
          this.isLoading = true
          const request = this.contentSlug
            ? this.$api.getContentBySlug(this.contentSlug)
            : this.$api.getContentById(this.contentId)
          const res = await request.then(response => {
            const data = response && response.data
            if (data && data.headRevision) {
              this.content = {
                type: ArticleType[data.contentType] || ArticleType.Article,
                html: data.headRevision.content.Html,
                title: data.headRevision.title,
                description: data.description,
                id: data.id,
                tags: data.tags || [],
                revisionId: data.headRevision.id,
                language: data.language
              }

              this.track({
                type: 'pageview',
                title: this.content.title
              })

              this.$emit('loaded', this.content)
            } else {
              this.isNotFound = true
              this.$emit('loaded', null)
            }
          })
        } catch (err) {
          this.isNotFound = true
          this.$emit('loaded', null)
          console.warn(err)
        } finally {
          this.isLoading = false
        }
      }
    }
  },
  watch: {
    isLoading (value) {
      this.$emit('isLoading', value)
    },
    contentId () {
      this.fetchArticle()
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
  @include sp-content-module {

  .advisor-article-modal {
    .modal-header {
      padding: 16px;
      .close {
        top: 12px;
        right: 26px;
      }
    }
    .modal-body {
      padding: 16px!important;
    }
    .advisor-article {
      width: 100%;
      height: auto;
      max-height: 80vh;
      min-height: 100px;
      overflow-y: auto;
      position: relative;
      .widget-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -30px;
        margin-top: -30px;
      }
    }
  }
  
}
</style>
