import Enum from './Enum'

const WidgetType = {
  Article: 1,
  Advisor: 2,
}

Enum.makeEnum(WidgetType, WidgetType)

export default WidgetType
