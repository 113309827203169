<template>
  <content-module class="problem-preview">
    <section
      v-if="problem.description && !result"
      class="problem-description"
    >
      <p>{{ problem.description }}</p>
    </section>

    <section
      v-if="problem.condition && !activeCause && !result"
      class="problem-condition text-center"
    >
      <h2>{{ problem.condition.conditionText }}</h2>
      <div class="buttons-wrap">
        <button
          class="btn btn-outline-primary"
          @click="openFirstCause"
        >
          {{ $t('troubleshooting.problem_solved') }}
        </button>
        <button
          class="btn btn-outline-primary"
          @click="loadProblem(problem.condition.cmtReferenceId)"
        >
          {{ $t('troubleshooting.problem_not_solved') }}
        </button>
      </div>

      <div
        v-if="problem.condition.helpText"
        class="problem-help-text"
      >
        <span class="ss-info" />
        <p>{{ problem.condition.helpText }}</p>
      </div>
    </section>

    <section v-else-if="activeCause">
      <div class="problem-cause text-center">
        <h2>
          {{ $t('troubleshooting.posible_causes', {active: activeIndexText, last: lastIndexText}) }}
          {{ activeCause.title }}
        </h2>
        <p v-if="!activeCause.hasInstruction">
          {{ activeCause.description }}
        </p>
      </div>

      <div class="problem-solution text-center">
        <h2 v-if="!activeCause.hasInstruction">
          {{ $t('troubleshooting.problem_solution') }}
          <span v-html="activeCause.solution" />
        </h2>

        <div
          v-if="activeCause && activeCause.hasInstruction"
          class="problem-solution-instruction"
        >
          <guide
            v-if="activeCause.instruction"
            :guide="activeCause.instruction"
          />
          <div
            v-else
            class="instruction-loader"
          >
            <sp-loader />
          </div>
        </div>
      </div>

      <div class="problem-cause-navigation">
        <h2>{{ $t('troubleshooting.did_this_solve') }}</h2>
        <div
          class="buttons-wrap"
          role="group"
        >
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="problemSolved(activeCause)"
          >
            {{ $t('troubleshooting.problem_solved') }}
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="problemNotSolved(activeCause)"
          >
            {{ $t('troubleshooting.problem_not_solved') }}
          </button>
        </div>
      </div>
    </section>

    <section
      v-else-if="!result"
      class="problem-start"
    >
      <p class="mb-0">
        {{ $t('troubleshooting.possible_solutions_found', {count: problem.causes.length}) }}
      </p>
      <p>{{ $t('troubleshooting.problem_start_desc') }}</p>
      <button
        class="btn btn-outline-primary"
        @click="openFirstCause"
      >
        {{ $t('buttons.start') }}
      </button>
    </section>

    <section
      v-else
      class="problem-solution-result"
    >
      <div v-if="result === 'solved'">
        <h2>{{ $t('troubleshooting.problem_solved') }}</h2>
        <p>{{ $t('troubleshooting.problem_solved_desc') }}</p>
      </div>
      <div
        v-else
        class="problem-not-solved text-center"
      >
        <p v-if="problem.endTexts">
          {{ problem.endTexts[0].Text }}
        </p>
        <p v-else>
          <b>{{ $t('troubleshooting.problem_no_solution_help_text') }}</b>
        </p>
      </div>
    </section>
  </content-module>
</template>
<script>
// import {Loader, ContentModule} from '~/components'
import Guide from './guide/Guide.vue'
import SpLoader from './Loader'
import ContentModule from './ContentModule'
import { GenericContentType } from '~/enums'
export default {
  components: {
    SpLoader,
    Guide,
    ContentModule
  },
  props: ['item', 'device'],
  data () {
    return {
      activeIndex: null,
      isSolved: false,
      instruction: null,
      result: null,
      cacheCause: null
    }
  },
  computed: {
    problem () {
      return this.item
    },
    activeCause () {
      if (this.activeIndex !== null) {
        return this.problem.causes[this.activeIndex]
      }
    },
    activeIndexText () {
      return this.activeIndex + 1 || 1
    },
    lastIndexText () {
      return this.problem.causes.length
    }
  },
  methods: {
    openFirstCause () {
      this.activeIndex = 0
      this.loadActiveInstruction()
    },
    loadActiveInstruction () {
      const genericId = this.getGuideId(this.activeCause)
      if (genericId) {
        const deviceId = this.device.id
        const cacheCause = (this.activeCause.hasInstruction = true)
        // TODO add caching
        // TODO add error handling
        this.$api.getGenericContent({ genericId, deviceId, genericType: GenericContentType.Instruction }).then((res) => {
          this.activeCause.instruction = res.data
          this.problem.causes = [...this.problem.causes]
        })
      }
      // TODO add sampling
      // this.$wmSample('cause', this.problem.id, cause.identity)
    },
    getGuideId (cause) {
      if (cause.solution) {
        const link = this.getLinkFromHtml(cause.solution)
        const id = link.getAttribute('data-device-type-guide-id')
        return id
      } else if (cause.suideId) {
        return cause.suideId
      }
    },
    problemNotSolved (cause) {
      this.instruction = null
      if (this.activeIndex < this.problem.causes.length - 1) {
        this.activeIndex = this.activeIndex + 1
        this.loadActiveInstruction()
      } else {
        this.activeIndex = null
        this.result = 'unsolved'
        // TODO add sampling
        // this.$wmSample('problemNotSolved', this.problem.id)
      }
    },
    problemSolved (cause) {
      this.activeIndex = null
      this.result = 'solved'
      // TODO add sampling
      // this.$wmSample('problemSolved', this.problem.id, cause.identity)
    },
    getLinkFromHtml (linkHtml) {
      const div = document.createElement('div')
      div.innerHTML = linkHtml
      const link = div.querySelector('a')
      return link || div
    },
    loadProblem (problemId) {
      this.activeIndex = null
      this.result = null
      this.$emit('changeProblem', {
        genericType: GenericContentType.Problem,
        genericId: problemId
      })
    }
  }
}
</script>

<style lang="scss">
@import "~/assets/scss/mixins.scss";
@include sp-content-module {
  .problem-preview {
    h1 {
      font-family: $font-family-bold;
      font-weight: 500;
      color: $text-base;
      margin-bottom: 0.25em;
    }

    h2 {
      font-size: 16px;
      font-family: $font-family-medium;
      font-weight: 400;
      margin-bottom: 0.5em;
      color: $text-primary;
    }

    .problem-description {
      margin-bottom: 1.5em;
    }

    .problem-condition {
      h2,
      .buttons-wrap {
        padding: 0 1em;
      }
    }

    .problem-help-text {
      padding: 2em;
      border-radius: $border-radius;
      background-color: $background-gray-color;
      text-align: center;

      > p {
        margin-bottom: 0;
      }

      .ss-info {
        font-size: 2em;
        color: $accent-color;
      }
    }

    .problem-cause {
      padding: 1em 0;
      border-top: 1px solid $divider-color;
    }

    .problem-solution {
      border-bottom: 1px solid $divider-color;
      padding-bottom: 1em;

      > p {
        font-size: 16px;
        color: $text-primary;
      }

      .instruction-loader {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .problem-solution-result {
      padding-top: 1.5em;
      border-top: 1px solid $divider-color;
    }

    .problem-cause-navigation {
      padding-top: 1.5em;

      h2 {
        text-align: center;
        margin-bottom: 0.25em;
        color: $text-base;
      }
    }

    .buttons-wrap {
      display: flex;
      margin-bottom: 2em;

      .btn {
        flex: 1 1 auto;

        &:first-child {
          margin-right: 1em;
        }
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;

      .buttons-wrap {
        justify-content: center;

        .btn {
          flex-grow: 0;
          min-width: 160px;
        }
      }
    }
  }
}
</style>
