<template>
  <div class="guide-slider-view">
    <swiper
      :options="swiperOptions"
      ref="slider"
    >
      <swiper-slide
        v-for="(step, i) in steps"
        :key="i"
        class="guide-slide"
      >
        <div
          v-html="step.bigHighlight"
          @click="goToNext"
        />
        <span class="guide-slide-nav steps-counter">{{
          $t('guide.step_x_of_x', { current: i + 1, total: steps.length })
        }}</span>
        <span class="alt-steps-counter">
          <span class="current-step">{{ i + 1 }}</span>
          <span class="step-divider">/</span>
          <span class="total-steps">{{ steps.length }}</span>
        </span>
        <p
          class="step-description"
          v-html="step.text"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
      />
      <div
        class="swiper-button-prev"
        slot="button-prev"
      />
      <div
        class="swiper-button-next"
        slot="button-next"
      />
    </swiper>
    <div class="slide-caption-static">
      <span class="guide-slide-nav steps-counter">{{
        $t('guide.step_x_of_x', { current: index + 1, total: steps.length })
      }}</span>
      <span class="alt-steps-counter">
        <span class="current-step">{{ index + 1 }}</span>
        <span class="step-divider">/</span>
        <span class="total-steps">{{ steps.length }}</span>
      </span>
      <p
        class="step-description"
        v-html="indexStep.text"
      />
    </div>
  </div>
</template>

<script>
import _ from 'underscore'
export default {
  props: ['device', 'guide'],
  data() {
    return {
      swiperOptions: {
        // prevButton: '.swiper-button-prev',
        // nextButton: '.swiper-button-next',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 1,
          clickable: true,
        },
        on: {
          slideChange: () => {
            this.index = this.$refs.slider.swiperInstance.activeIndex
          },
        },
      },
      index: 0,
    }
  },
  computed: {
    steps() {
      return _.flatten(
        this.guide.blocks.map((b) =>
          b.steps.map((s) => ({ title: b.title, ...s })),
        ),
      )
    },
    indexStep() {
      return this.steps[this.index]
    },
  },
  methods: {
    goToNext(event) {
      if (event.target.classList.contains('wm-hl-pointer')) {
        const swiper = this.$refs.slider.swiperInstance
        swiper.slideNext()
      }
    },
  },
  watch: {
    guide() {
      const swiper = this.$refs.slider.swiperInstance
      swiper.slideTo(0)
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .guide-slider-view {
    .guide-slide {
      margin-top: 2.5em;
      .guide-screen {
        margin-bottom: 2em;
      }
      .guide-slide-nav {
        font-size: 0.875em;
        // color: $color-light-gray;
      }
      .wm-hl-callout {
        z-index: 5;
      }
    }
    .alt-steps-counter {
      display: none;
    }
    .step-description {
      font-size: 1.075em;
      span.pointer {
        font-weight: 500;
      }
    }
    .slide-caption-static,
    .swiper-pagination {
      display: none;
    }
  }
}
</style>
