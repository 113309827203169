import {GenericContentType} from '~/enums'
export default class AgentsApiService {
  constructor({http}) {
    this.http = http
  }

  getContentById(id) {
    return this.http.get(`/authoring/publishing/content/3/${id}`)
  }
  getRelatedContentById(originId, relatedId) {
    return this.http.get(`/authoring/publishing/content/3/${originId}/related-article/${relatedId}`)
  }
  getContentBySlug(slug) {
    throw Error('Not implemented')
  }
  searchGenericContentByDevice({genericId, genericType, query}) {
    const method = getMethodByGenericType(genericType)
    const request = {
      contextItemId: '' + genericId,
      query,
    }
    if (GenericContentType[genericType] === GenericContentType.Problem) {
      request.contextItemName = '' + genericId
    } else {
      request.contextItemId = '' + genericId
    }
    return this.http.post(`/search/${method}/`, request)
  }
  getGenericContent({deviceId, genericType, genericId}) {
    let method
    if (GenericContentType[genericType] === GenericContentType.Instruction) {
      method = 'guides'
    } else if (GenericContentType[genericType] === GenericContentType.Problem) {
      method = 'problems'
    } else if (GenericContentType[genericType] === GenericContentType.Topic) {
      method = 'topics'
    } else {
      console.error('Wrong generic type', {genericType})
      return
    }
    return this.http.get(`/${method}/${deviceId}/${genericId}`)
  }
  getBranchFlowBlocksByIds(ids) {
    return this.http.get(
      `/authoring/content/diagnosticflow/blocks?${ids.map((id) => `ids=${id}`).join('&')}`,
    )
  }

  // SP Advisor
  getAdvisorById(id) {
    return this.http.get(`/advisor/journeys/${id}/details`)
  }
  getAdvisorBrandsById(id) {
    return this.http.get(`/advisor-products/${id}/brands`)
  }
  getAdvisorSpecifications(deviceType, language) {
    return this.http.get(`/advisor-specifications`, {params: {deviceType, language}})
  }
  getAdvisorSearchProducts(requestModel, contentType) {
    const path = contentType.toLowerCase() === 'article' ? '/advisor-products/search-articles' : '/advisor-products/search-devices'
    
    return this.http.post(path, requestModel)
  }
  getAdvisorSearchAvailableOptions(requestModel) {
    return this.http.post('/advisor-products/search-available-options', requestModel)
  }
}

function getMethodByGenericType(genericType) {
  let method
  if (GenericContentType[genericType] === GenericContentType.Instruction) {
    method = 'instruction'
  } else if (GenericContentType[genericType] === GenericContentType.Problem) {
    method = 'problem'
  } else if (GenericContentType[genericType] === GenericContentType.Topic) {
    method = 'topic'
  } else {
    console.error('Wrong generic type', {genericType})
    return
  }

  return method
}
