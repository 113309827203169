import Enum from './Enum'
const ArticlePreviewType = {
  Default: 'Default',
  Flow: 'Flow',
  Image: 'Image',
  Step: 'Step',
  Video: 'Video'
}

Enum.makeEnum(ArticlePreviewType, ArticlePreviewType)

export default ArticlePreviewType
