import { WidgetType } from '~/enums'
import WidgetCreator from './factories/WidgetCreator'
import makeServices from '~/services'

let widgetCreator

let inited = false
export const init = ({ token, client, channelId, apiUrl, apiType, trackerType, language, samplingOptions = { enabled: false } } = {}) => {
  if (inited) return

  client = client || CLIENT
  if (!client) {
    throw new Error('Provide a client data')
  }
  window.currentClient = client

  if (!channelId) {
    throw new Error('Provide a channelId (self-service - 4, embedded - 6)')
  }

  const gaCode = client ? (client.gaCode instanceof Object ? client.gaCode[language] : client.gaCode) : ''
  const trackerOptions = {
    trackerType: trackerType || 'globalGA',
    gaCode,
    dimensions: {
      dimension1: channelId || 'SP Content Embedded'
    }
  }

  const services = makeServices({ token, client, channelId, apiUrl, apiType, trackerOptions, samplingOptions, language })
  widgetCreator = new WidgetCreator(services)
  inited = true
}

export const createWidget = (type, attrs, eventListeners) => {
  if (!WidgetType[type]) {
    throw new Error(`Unsupported widget type: ${type}`)
  }

  if (!attrs || (!attrs.content && !attrs['content-id'] && !attrs['content-slug'])) {
    throw new Error('Provide content-id, content-slug or content for the widget')
  }

  return widgetCreator.create(type, attrs, eventListeners)
}

export const utils = {
  getTemplateById (id) {
    const el = document.getElementById(id)
    if (el) {
      const out = el.outerHTML
      el.parentNode.removeChild(el)
      return out
    } else {
      return '<div>No template found for: #' + id + '</div>'
    }
  }
}
