<template>
  <div class="switch">
    <label class="switch-button">
      <input
        type="checkbox"
        @click="toggleCheckbox"
      >
      <div class="switch-button_slider round" />
    </label>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'label'],
  data () {
    const initialValue = this.value || false
    return {
      checkbox: initialValue
    }
  },
  methods: {
    toggleCheckbox () {
      this.checkbox = !this.checkbox
      this.$emit('change', this.checkbox)
    }
  }
}
</script>

<style lang="scss">
.switch {
  display: flex;
  align-items: center;
  .switch-button {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 31px;
    margin: 0;
    @media (max-width: 768px) {
      width: 36px;
      height: 20px;
    }

    input {
      display: none;
    }
    &_slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-dark;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: "";
        height: 27px;
        width: 27px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        @media (max-width: 768px) {
          width: 16px;
          height: 16px;
        }
      }
      &.round {
        border-radius: 31px;

        &:before {
          border-radius: 50%;
        }
      }
    }

    input:checked + .switch-button_slider {
      background-color: $primary;
    }

    input:focus + .switch-button_slider {
      box-shadow: 0 0 1px $primary;
    }
    input:checked + .switch-button_slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);

      @media (max-width: 768px) {
        transform: translateX(16px);
      }
    }
  }
  .label {
    font-size: $font-size-base;
    padding-left: 16px;
  }
}

</style>
