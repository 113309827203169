var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'advisor-preview',
    {
      'result-wrapper': _vm.result.state && !_vm.comparison && !_vm.isNoMatch,
      'no-match': _vm.isNoMatch,
      'comparison-wrapper': !!_vm.comparison,
    } ]},[(!_vm.result.state)?[(_vm.themeName === 'movistar')?_c('div',{staticClass:"top-title-text"},[_vm._v("\n      Ayúdame a elegir el dispositivo perfecto\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"steps"},[_c('div',{staticClass:"step-wrap"},[(_vm.activeStep.id > 0)?_c('custom-step',{key:_vm.activeIndex,attrs:{"journey":_vm.journey,"model":_vm.activeStep,"values":_vm.selected,"answers":_vm.answers,"search-available-options":_vm.searchAvailableOptions},on:{"update:model":function($event){_vm.activeStep=$event},"update:values":function($event){_vm.selected=$event}}}):(_vm.activeStep.id === 0)?_c('priority-step',_vm._b({key:_vm.activeIndex,attrs:{"model":_vm.activeStep,"values":_vm.priorities},on:{"update:values":function($event){_vm.priorities=$event}}},'priority-step',_vm.options,false)):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"progress-wrap"},[_c('div',{staticClass:"progress"},_vm._l((_vm.steps),function(point,index){return _c('div',{key:index,staticClass:"progress-point",class:{
            passed: index < _vm.activeIndex,
            active: index === _vm.activeIndex,
          }})}),0)])]:[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideResults),expression:"!hideResults"}]},[(!_vm.comparison && _vm.contentType !== 'Article')?_c('result',_vm._b({attrs:{"journey":_vm.journey,"model":_vm.result.model,"devices":_vm.result.devices,"compared-devices":_vm.result.compared,"session-id":_vm.sessionId},on:{"update:comparedDevices":function($event){return _vm.$set(_vm.result, "compared", $event)},"update:compared-devices":function($event){return _vm.$set(_vm.result, "compared", $event)}}},'result',_vm.options,false)):_vm._e(),_vm._v(" "),(_vm.result.state && _vm.contentType === 'Article')?_c('result-article',_vm._b({attrs:{"journey":_vm.journey,"model":_vm.result.model,"products":_vm.result.devices,"session-id":_vm.sessionId}},'result-article',_vm.options,false)):_c('div',{staticClass:"comparison-wrap"},[_c('comparison',{attrs:{"devices":_vm.result.devices,"numbers":_vm.numbers}})],1)],1)],_vm._v(" "),_c('div',{staticClass:"controls-wrap"},[(_vm.hasAnswers)?_c('button',{staticClass:"btn btn-control btn-restart",attrs:{"type":"button"},on:{"click":_vm.restart}},[_c('span',{staticClass:"icon"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('advisor.btn_restart')))])]):_vm._e(),_vm._v(" "),(_vm.activeIndex > 0)?_c('button',{staticClass:"btn btn-control btn-prev",attrs:{"type":"button"},on:{"click":_vm.prevStep}},[_vm._v("\n      "+_vm._s(_vm.$t('advisor.btn_prev'))+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.result.state)?_c('button',{staticClass:"btn btn-control btn-next",attrs:{"type":"button","disabled":!_vm.canSkip},on:{"click":_vm.nextStep}},[_vm._v("\n      "+_vm._s(_vm.$t('advisor.btn_next'))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.result.state && !_vm.comparison && !_vm.isNoMatch && _vm.contentType !== 'Article'
      )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideResults),expression:"!hideResults"}],staticClass:"btn btn-control btn-compare",attrs:{"type":"button"},on:{"click":_vm.toggleComparison}},[_vm._v("\n      "+_vm._s(_vm.$t('advisor.btn_compare'))+"\n    ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }