<template>
  <div
    class="b-overlay"
    v-show="isOverlayShown"
    @click="hideOverlay"
    @shown="toggleScrollable"
    @hidden="toggleScrollable"
  >
    <div>
      <div class="overlay-content">
        <div class="rotateImage" />
        <h4>
          {{ text }}
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: { type: String, default: 'Please, turn your phone for better experience' }
  },
  data () {
    return {
      isOverlayShown: window.matchMedia('(orientation: portrait)').matches
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.hideOverlay()
    })
  },
  destroyed () {
    window.removeEventListener('resize', () => {
      this.hideOverlay()
    })
  },
  methods: {
    hideOverlay () {
      this.isOverlayShown = false
    },
    toggleScrollable () {
      document.body.classList.toggle('noscrollable')
    }
  }
}
</script>
<style lang="scss">

.b-overlay {
  position: fixed;
  opacity: 0.8;
  background: $white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .overlay-content {
    width: 80vw;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;

    .rotateImage {
      position: relative;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      margin-bottom: 30px;

      &:before,
      &:after {
        content: ' ';
        position: absolute;
        left: 60px;
        bottom: 0;
        display: block;
        width: 60px;
        height: 120px;
      }
      &:before {
        border: 2px dotted $black;
      }
      &:after {
        border: 2px solid $black;
        animation: rotate-device 3s infinite ease-out;
      }
    }
  }
  .bg-dark {
    background-color: $black;
  }
}
@keyframes rotate-device {
  to {
    transform: rotate(-90deg);
    left: 30px;
    bottom: -30px;
  }
}
</style>
