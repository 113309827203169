<template>
  <div
    class="guide-list-view"
    :id="'guide' + guide.cmtReferenceId"
  >
    <div
      v-for="(block, i) in guide.blocks"
      :key="i"
      class="guide-block"
    >
      <h3>
        <span class="step-counter">{{ i+1 }}.</span>
        <span v-html="block.title" />
      </h3>
      <div class="block-body">
        <div
          v-for="(step, i) in block.steps"
          :key="i"
          class="step-row d-flex border-top"
        >
          <div class="w-75">
            <p v-html="step.text" />
          </div>
          <div class="w-25">
            <div v-html="step.smallHighlight" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['device', 'guide'],
  updated () {
    this.$emit('update')
  },
  mounted () {
    this.$emit('update')
  }
}
</script>

<style lang="scss">
@import "~/assets/scss/mixins.scss";
@include sp-content-module {
    .guide-list-view {
      .step-row {
        padding: 1em 0;
        hr {
          width: 100%;
          margin-top: 0;
          border-top: 1px solid $border-color;
          box-shadow: none;
        }
      }

      .guide-block {
        margin-bottom: 3em;
        .wm-hl-callout {
          z-index: 5;
        }
        .wm-hl-highlight-main {
          text-align: center;
        }
      }
    }
  }
</style>
