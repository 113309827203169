<template>
  <div
    class="instruction-preview"
    id="SpInstruction"
  >
    <p
      v-html="item.Description"
      class="instruction-description"
    />
    <div class="slider-view">
      <div class="sp-b-steps">
        <div
          class="sp-b-steps-container swiper-container"
          ref="slider"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide sp-b-step"
              v-for="(step, index) in steps"
              :key="index"
            >
              <div
                v-html="step.BigHighlight"
                @click="goToNextSlide"
              />
              <div class="sp-b-steps-caption">
                <span class="d-block text-center">{{ getStepTranslation(index + 1, steps.length) }}</span>
                <h4
                  v-if="currentBlock"
                  class="sp-b-step-title"
                  v-html="currentBlock.Title"
                />
                <p
                  class="sp-b-step-description"
                  v-html="step.Text"
                  @click="goToNextSlide"
                />
                <p
                  v-if="step.Note"
                  class="sp-b-step-note"
                  v-html="step.Note"
                />
                <p
                  v-if="currentBlock && currentBlock.Description"
                  class="sp-b-step-note"
                  v-html="currentBlock.Description"
                />
              </div>
            </div>
          </div>
          <div class="swiper-pagination">
            <span class="active" />
            <span class="total" />
          </div>
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item'],
  data () {
    return {
      solution: null,
      swiperInstance: null
    }
  },
  computed: {
    steps () {
      return this.item.Blocks.map((b) => b.Steps.map((s) => ({ title: b.Title, ...s }))).flat()
    },
    currentStepLabel () {
      return this.$t('guide.step_x_of_x', { current: this.currentStepIndex + 1, total: this.steps.length })
    },
    currentStepIndex () {
      return (this.swiperInstance && this.swiperInstance.activeIndex) || 0
    },
    currentStep () {
      return this.steps && this.steps[this.currentStepIndex]
    },
    currentBlock () {
      return (
        this.currentStep && this.item.Blocks.find((b) => b.Steps.some((step) => step.Text === this.currentStep.Text))
      )
    }
  },
  mounted () {
    this.swiperInstance = new Swiper(this.$refs.slider, {
      noSwiping: false,
      roundLength: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },

      // To support 3.x version
      prevButton: '.swiper-button-prev',
      nextButton: '.swiper-button-next',

      // 4.x version
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next'
      }
    })
  },
  methods: {
    getStepTranslation (step, length) {
      return this.$t('guide.step_x_of_x', { current: step, total: length })
    },
    goToNextSlide (event) {
      if (event.target.classList.contains('wm-hl-pointer') || event.target.classList.contains('pointer')) {
        if (this.swiperInstance.isEnd) {
          this.swiperInstance.slideTo(0)
        } else {
          this.swiperInstance.slideNext()
        }
      }
    },
    getLinkFromHtml (linkHtml) {
      const div = document.createElement('div')
      div.innerHTML = linkHtml
      const link = div.querySelector('a')
      return link || div
    }
  }
}
</script>
