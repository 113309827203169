<template>
  <div
    ref="magnifier"
    class="magnifier-wrap"
    :style="{ padding: padding + 'px' }"
  >
    <div class="content">
      <slot name="content" />
    </div>
    <magnifier-glass
      :x="positionX"
      :y="positionY"
      :size="glassSize"
      :zoom="zoomLevel"
      :inner-html="innerHtml"
      :padding="padding"
      :glass-style="glassStyle"
    />
  </div>
</template>
<script>
import MagnifierGlass from './MagnifierGlass.vue'
export default {
  components: {
    MagnifierGlass,
  },
  props: {
    padding: { type: Number, default: 0 },
    glassSize: { type: Number, default: 0 },
    glassStyle: {
      type: Object,
      default: () => {
        return null
      },
    },
    zoomLevel: { type: Number, default: 0 },
  },
  data() {
    return {
      positionX: 0,
      positionY: 0,
      innerHtml: '',
    }
  },
  mounted() {
    setTimeout(() => {
      var wrap = this.$refs.magnifier
      if (!wrap) return
      var content = wrap.querySelector('.content')
      var cloned = content.cloneNode(true)
      cloned.style.height = content.getBoundingClientRect().height + 'px'
      cloned.style.width = content.getBoundingClientRect().width + 'px'

      this.innerHtml = cloned.outerHTML

      wrap.addEventListener('mousemove', (e) => {
        var x = e.pageX - (window.scrollX + wrap.getBoundingClientRect().left)
        var y = e.pageY - (window.scrollY + wrap.getBoundingClientRect().top)

        this.positionX = x - this.glassSize / 2
        this.positionY = y - this.glassSize / 2
      })
    }, 1500)
  },
}
</script>
<style lang="scss">
.magnifier-wrap {
  position: relative;
  overflow: hidden;
}

.magnifier-wrap .magnifier-glass {
  display: none;
}

.magnifier-wrap:hover .magnifier-glass {
  display: block;
}
</style>
