import { Enum } from '~/enums'

const DecisionBranchView = {
  Button: 1,
  Dropdown: 2
}

Enum.makeEnum(DecisionBranchView, DecisionBranchView)

export default DecisionBranchView
