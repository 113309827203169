<template>
  <div
    ref="magnifierGlass"
    class="magnifier-glass"
    :style="{
      ...glassStyle,
      width: size + 'px',
      height: size + 'px',
      top: y + 'px',
      left: x + 'px',
    }"
  >
    <div
      :style="{
        zoom: zoom,
        transform: `
            translateX(${-1 * (x + size / 2) + size / (2 * zoom) + padding}px)
            translateY(${-1 * (y + size / 2) + size / (2 * zoom) + padding}px)
        `,
      }"
      v-html="innerHtml"
    />
  </div>
</template>
<script>
export default {
  props: {
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    size: { type: Number, default: 0 },
    zoom: { type: Number, default: 0 },
    innerHtml: { type: String, default: '' },
    padding: { type: Number, default: 0 },
    glassStyle: {
      type: Object,
      default: () => {
        return null
      }
    }
  }
}
</script>
<style lang="scss">
.magnifier-glass {
  position: absolute;
  overflow: hidden;
  background-color: white;
  z-index: 10;
}
</style>
