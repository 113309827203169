import { GenericContentType } from '~/enums'
import { toQueryString } from '~/utils'
export default class ApiService {
  constructor({http}) {
    this.http = http
  }

  getContentById(id) {
    return this.http.get(`/articles/${id}`)
  }
  getContentBySlug(slug) {
    return this.http.get(`/articles/${slug}`)
  }
  getRelatedContentById(originId, relatedId) {
    return this.http.get(`/articles/${originId}/related-article/${relatedId}`)
  }
  searchGenericContentByDevice({genericType, genericId, query, genericName}) {
    const method = getMethodByGenericType(genericType)
    const request = {
      contextItemId: '' + genericId,
      query,
    }

    if (GenericContentType[genericType] === GenericContentType.Problem) {
      request.contextItemId = genericName
    } else {
      request.contextItemId = '' + genericId
    }
    return this.http.post(`/search/${method}/`, request)
  }
  getGenericContent({deviceId, genericType, genericId}) {
    let method
    if (GenericContentType[genericType] === GenericContentType.Instruction) {
      method = 'guides'
    } else if (GenericContentType[genericType] === GenericContentType.Problem) {
      method = 'problems'
    } else if (GenericContentType[genericType] === GenericContentType.Topic) {
      method = 'topics'
    } else {
      console.error('Wrong generic type', {genericType})
      return
    }
    return this.http.get(`/${method}/${deviceId}/${genericId}`)
  }
  getBranchFlowBlocksByIds(ids) {
    return this.http.get(
      `/articles/branchFlowBlocks?${ids.map((id) => `ids=${id}`).join('&')}`,
    )
  }

  // SP Advisor
  getAdvisorById(id) {
    return this.http.get(`/advisor/${id}/details`)
  }
  getAdvisorBrandsById(id) {
    return this.http.get(`/advisor/${id}/brands`)
  }
  getAdvisorSpecifications(deviceType, language) {
    return this.http.get(`/advisor/specifications`, {params: {deviceType, language}})
  }
  getAdvisorSearchProducts(requestModel, contentType) {
    const path = contentType.toLowerCase() === 'article' ? '/advisor/get-search-articles' : '/advisor/get-search-devices'    
    return this.http.get(path + '?' + toQueryString(requestModel))
  }
  getAdvisorSearchAvailableOptions(requestModel) {
    return this.http.get('/advisor/get-available-options' + '?' + toQueryString(requestModel))
  }
}

function getMethodByGenericType(genericType) {
  let method
  if (GenericContentType[genericType] === GenericContentType.Instruction) {
    method = 'instruction'
  } else if (GenericContentType[genericType] === GenericContentType.Problem) {
    method = 'problem'
  } else if (GenericContentType[genericType] === GenericContentType.Topic) {
    method = 'topic'
  } else {
    console.error('Wrong generic type', {genericType})
    return
  }

  return method
}