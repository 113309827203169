export default {
  Text: 'Text',
  RichText: 'RichText',
  TableText: 'TableText',
  Image: 'Image',
  Video: 'Video',
  StepByStep: 'StepByStep',
  Table: 'Table',
  Button: 'Button'
}
