import Enum from './Enum'
const GenericContentType = {
  Topic: 1,
  Problem: 2,
  Instruction: 3
}

Enum.makeEnum(GenericContentType, GenericContentType)

export default GenericContentType
