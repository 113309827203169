import Enum from './Enum'

const WidgetApi = {
  Selfservice: 1,
  SpAgents: 2,
}

Enum.makeEnum(WidgetApi, WidgetApi)

export default WidgetApi
