<template>
  <div
    class="image-marker-wrap"
    :class="wrapClass"
    :style="data.style"
  >
    <div
      class="image-marker"
      :class="classString"
      @click="$emit('click', data)"
    >
      <span
        v-if="!data.arrow || data.arrow === 'none'"
        class="pointer-index"
      >
        {{ index }}
      </span>
      <div>
        <span
          v-if="data.arrow && data.arrow !== 'none'"
          class="icon-caret"
          :class="data.arrow"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data', 'index'],
  computed: {
    classString() {
      const list = []
      const { shape, size, color, animate, arrow, action } = this.data
      list.push(`bg-${color}`)
      action && action !== 'none' && list.push('cursor-pointer')
      if (shape === 'circle' || !shape) {
        list.push('circle')
        size && list.push(`size-${size}`)
        animate && animate === true && arrow !== 'none' && list.push('animate')
        animate && animate === 'pulse' && list.push('pulse')
        animate && animate === 'arrow' && list.push('animate')
        arrow && arrow !== 'none' && list.push('arrow') && list.push(arrow)
      }
      return list.join(' ')
    },
    isMobile() {
      return window.innerWidth < 1000
    },
    wrapClass() {
      const list = []
      const { size } = this.data
      list.push(`size-${size}`)

      return list.join(' ')
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
.image-marker-popover {
  position: relative;
  background: $dark;
  color: $white;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  max-width: 240px;
  z-index: 20;
  font-family: $font-family;
  & .popover-body > p {
    margin: 0;
  }
  &::after {
    content: '';
    position: absolute;
    background: $dark;
    width: 10px;
    height: 10px;
    top: 50%;
  }
  &.bs-popover-left::after {
    transform: translate(50%, -50%) rotate(45deg);
    right: 0;
  }
  &.bs-popover-right::after {
    transform: translate(-50%, -50%) rotate(45deg);
    left: 0;
  }
}
@include sp-content-module {
  $marker-size-sm: 20px !default;
  $marker-size-md: 30px !default;
  $marker-size-lg: 40px !default;
  $marker-color-blue: $brand-primary;
  $marker-color-green: $brand-success;
  $marker-color-yellow: $brand-warning;
  $marker-color-red: $brand-danger;
  $marker-color-lightblue: $brand-info;
  $marker-color-white: $white;
  $marker-color-dark: $gray-dark;

  .image-marker-wrap {
    position: absolute;
    z-index: 2;
    display: flex;
    &.size-sm {
      @include squareSize($marker-size-sm);
      font-size: 0.75em;
    }
    &.size-md {
      @include squareSize($marker-size-md);
      font-size: 1em;
    }
    &.size-lg {
      @include squareSize(($marker-size-lg));
      font-size: 1.25em;
    }
  }
  .image-marker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &.circle {
      border-radius: 50%;
    }
    &.bg-primary {
      background-color: $marker-color-blue !important;
    }

    &.bg-success {
      background-color: $marker-color-green !important;
    }

    &.bg-warning {
      background-color: $marker-color-yellow !important;
    }

    &.bg-danger {
      background-color: $marker-color-red !important;
    }

    &.bg-info {
      background-color: $marker-color-lightblue !important;
    }

    &.bg-dark {
      background-color: $marker-color-dark !important;
    }

    .image-marker-arrow {
      width: 1em;
      height: 1em;
      line-height: 1;
    }

    span.icon-caret {
      display: flex;
      width: 1em;
      height: 1em;
      font-size: 1em;
      background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2024%22%3E%3Cpath%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%226%22%20fill%3D%22none%22%20d%3D%22M3%206%20L16%2018%20L29%206%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E);
      background-repeat: no-repeat;
      background-position: 50%;

      &.up {
        transform: rotate(180deg);
      }

      &.right {
        transform: rotate(270deg);
      }

      &.down {
        transform: rotate(0);
      }

      &.left {
        transform: rotate(90deg);
      }
    }

    &.bg-white {
      span.icon-caret {
        background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2024%22%3E%3Cpath%20stroke%3D%22%23AAAAAA%22%20stroke-width%3D%226%22%20fill%3D%22none%22%20d%3D%22M3%206%20L16%2018%20L29%206%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E);
      }
    }

    &.pulse {
      animation: image-marker-pulsate 0.75s infinite;
    }

    &.cursor-pointer {
      cursor: pointer;
    }

    &.animate.arrow {
      animation: none;

      &.up > div {
        animation: image-marker-arrow-up 0.75s infinite;
      }

      &.right > div {
        animation: image-marker-arrow-right 0.75s infinite;
      }

      &.down > div {
        animation: image-marker-arrow-down 0.75s infinite;
      }

      &.left > div {
        animation: image-marker-arrow-left 0.75s infinite;
      }

      @keyframes image-marker-arrow-up {
        0% {
          opacity: 0;
          transform: translate(0, 40%);
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
          transform: translate(0, -40%);
        }
      }

      @keyframes image-marker-arrow-right {
        0% {
          opacity: 0;
          transform: translate(-40%, 0);
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
          transform: translate(40%, 0);
        }
      }

      @keyframes image-marker-arrow-down {
        0% {
          opacity: 0;
          transform: translate(0, -40%);
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
          transform: translate(0, 40%);
        }
      }

      @keyframes image-marker-arrow-left {
        0% {
          opacity: 0;
          transform: translate(40%, 0);
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
          transform: translate(-40%, 0);
        }
      }
    }

    @keyframes image-marker-pulsate {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.5);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}
</style>
