export function replaceOrigin (url, origin) {
  if (url) {
    return url.replace(/^https?:\/\/.+?\//ig, origin)
  }
}

export function unicodeToSymbol (value) {
  const replaceMap = {
    337: 'ő'
  }
  return value.replace(/&#(\d+);/g, (n, i) => replaceMap[i] || '□')
}

export function truncateText (text, size) {
  if (text && size) {
    return text.length > size ? text.substr(0, size) + '...' : text
  }
  return text
}

export default {
  replaceOrigin,
  unicodeToSymbol,
  truncateText
}
