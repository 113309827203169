<template>
  <div class="result-wrap">
    <template v-if="devices.length > 0">
      <h1
        class="title"
        v-html="title"
      />
      <p class="description" v-html="model.description" />

      <div
        class="devices-wrap"
        :class="[{'two-devices': themeName === 'movistar' && devices.length === 2}]"
      >
        <div
          v-for="(d, index) in devices.slice(0, numbers)"
          :key="index"
          :class="['device-card', {'highest-score-device': index === 0}, {compared: comparedDevices.includes(d.id)}]"
          @click="handleSelect(d, index)"
        >
          <div class="header">
            <span v-if="headersLabelsList[index]">{{ headersLabelsList[index] }}</span>
          </div>
          <div class="image-wrap">
            <img :src="d.image">
          </div>
          <div
            v-if="themeName !== 'movistar'"
            class="btn-compare-wrap"
          >
            <button
              v-if="isCompareButton"
              type="button"
              class="btn btn-compare"
              @click.stop="handleCompare(d)"
            >
              <span>
                <template v-if="!comparedDevices.includes(d.id)">Compare</template>
                <template v-else>Added for comparison</template>
              </span>
            </button>
          </div>
          <div class="name">
            {{ d.name }}
          </div>
          <button
            v-if="themeName === 'movistar' && index === 0 && d.eshopLink"
            class="btn-buy-device"
          >
            <a
              :href="d.eshopLink"
              class="buy-device-link"
            >{{ $t('advisor.want_it') }}</a>
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <div class="bell" />
        <p
          class="text-no-matching"
          v-html="$t('advisor.text_no-matching')"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    return {
      themeName: themeName || 'generic'
    }
  },
  props: {
    journey: { type: Object, default: () => ({}) },
    model: { type: Object, default: () => ({}) },
    devices: { type: Array, default: () => [] },
    comparedDevices: { type: Array, default: () => [] },
    numbers: { type: Number, default: 3 },
    headers: { type: Array, default: () => [] },
    sessionId: { type: String, default: undefined },
  },
  mounted () {
    this.$root.$on('app::advisor::device::remove-device-from-comparison', this.handleRemoveDeviceFromCompare)
    this.$root.$on('app::advisor::device::remove-all-devices-from-comparison', this.handleRemoveAllDevicesFromCompare)
  },
  beforeDestroy () {
    this.$root.$off('app::advisor::device::remove-device-from-comparison', this.handleRemoveDeviceFromCompare)
    this.$root.$off('app::advisor::device::remove-all-devices-from-comparison', this.handleRemoveAllDevicesFromCompare)
  },
  computed: {
    isCompareButton () {
      const spContentModule = document.querySelector('.sp-content-module').attributes
      if (
        spContentModule['widget-class'] &&
        spContentModule['widget-class'].nodeValue.includes('sp-retail')
      ) {
        return false
      } else if (this.themeName === 'vodafone' && this.themeName === 'movistar') {
        return false
      }
      return true
    },
    title () {
      const device = this.devices[0] || {}
      const template = `<span class="device-name">${device.name}</span>`

      // {device-name} key are using in the old version of SP Advisor
      return this.model.title.replace('{device-name}', template).replace('{product-name}', template)
    },
    headersLabelsList () {
      return this.headers.length > 0
        ? this.headers
        : [
          this.$t('advisor.label_result-device-1'),
          this.$t('advisor.label_result-device-2'),
          this.$t('advisor.label_result-device-3')
        ]
    }
  },
  methods: {
    handleCompare (device) {
      const copied = JSON.parse(JSON.stringify(this.comparedDevices))

      const id = device.masterId || device.id
      const index = this.comparedDevices.findIndex(x => x === id)
      if (index > -1) {
        copied.splice(index, 1)
      } else {
        copied.push(id)
      }

      this.$emit('update:comparedDevices', copied)
      this.$root.$emit('app::advisor::device::compare', device)
    },
    handleSelect (device, index) {
      this.$root.$emit('app::advisor::device::select', device)

      this.sampleBuyBtnClick(device.id, index)
    },
    handleRemoveDeviceFromCompare (device) {
      const copied = JSON.parse(JSON.stringify(this.comparedDevices))

      const id = device.masterId || device.id
      const index = copied.findIndex(x => x === id)
      if (index > -1) {
        copied.splice(index, 1)
      }

      this.$emit('update:comparedDevices', copied)
    },
    handleRemoveAllDevicesFromCompare () {
      this.$emit('update:comparedDevices', [])
    },
    sampleBuyBtnClick (productId, index) {
      const { id, contentType, language: languageId } = this.journey
      this.$sampling.spSample('advisorBuyButtonClick', id, contentType, languageId, this.sessionId, productId, index + 1)
    },
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .result-wrap {
    .title {
      .device-name {
        font-weight: 600;
      }
    }
    .description {
    }
    .devices-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .device-card {
        flex: 1;
        padding: 16px;
        text-align: center;
        border: 1px solid $dark;
        cursor: pointer;

        .header {
          margin-bottom: 8px;
        }

        .image-wrap {
          height: 180px;

          img {
            max-height: 100%;
            max-width: 50%;
            margin: auto;
          }
        }

        .btn-compare-wrap {
          margin: 8px 0;
          .btn-compare {
            font-size: 12px;
          }
        }

        .name {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
