var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"flowContainer",staticClass:"diagnostic-flow-preview",style:(_vm.flowContainerStyles)},[_c('transition-group',{ref:"pathItems",attrs:{"name":"path","tag":"div"},on:{"after-enter":_vm.scrollToLast}},[_vm._l((_vm.path),function(b,i){
var _obj;
return [(
          !(
            (b.typeName === _vm.TYPE.Terminator && _vm.hideTerminator) ||
            _vm.hideExtConnector(b)
          )
        )?_c('div',{key:'' + i + b.key,class:( _obj = {
          'path-item': true,
          terminated: b.typeName === _vm.TYPE.Terminator,
          external: b.typeName === _vm.TYPE.ExtConnector,
          processed: '' + i + b.key in _vm.processedMap,
          opaque: b.isOpaque
        }, _obj['size-' + _vm.breakpoint] = true, _obj['type-' + b.typeName.toLowerCase()] = true, _obj )},[(b.props && b.props.title.value)?_c('h3',[_vm._v("\n          "+_vm._s(b.props.title.value)+"\n        ")]):_vm._e(),_vm._v(" "),_c('p',{staticStyle:{"display":"none"}},[_vm._v("\n          "+_vm._s(b.key)+"\n        ")]),_vm._v(" "),(
            b.props &&
            b.props.caption.value &&
            !b.props.caption.options.isInvisible
          )?_c('p',{staticClass:"block-description",domProps:{"innerHTML":_vm._s(b.props.caption.value)},on:{"click":_vm.hyperlinkEvent}}):_vm._e(),_vm._v(" "),(b.typeName === _vm.TYPE.Process || b.typeName === _vm.TYPE.Terminator)?_c('div',{staticClass:"process-content"},[(b.root)?_c('CustomArticle',{attrs:{"content":b.root},on:{"button-action":function($event){return _vm.onProcessAction(b, $event)},"button-click":function($event){return _vm.onButtonClick(b, $event)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(b.typeName === _vm.TYPE.Decision)?_c('div',{class:'decision-buttons' +
            (b.props.align && b.props.align.value
              ? ' justify-content-' + b.props.align.value
              : '')},[(
              b.props.branchView &&
              Number(b.props.branchView.value) ===
                Number(_vm.DecisionBranchView.Dropdown)
            )?[_c('b-dropdown',{class:{ 'decision-dropdown': true, 'has-value': !!b.answer },attrs:{"variant":"link","dropup":"","no-flip":""},on:{"show":function($event){b.isOpaque = true},"hide":function($event){b.isOpaque = false}}},[_c('template',{slot:"button-content"},[(b.answer)?_c('div',{staticClass:"selected-wrap"},[_c('div',{staticClass:"image-wrap"},[(b.answer && b.answer.image && b.answer.image.url)?_c('img',{attrs:{"src":_vm._f("cdn")(b.answer.image.url &&
                        (b.answer.image.url + '?width=30'))}}):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"label text-truncate"},[_vm._v("\n                    "+_vm._s(b.answer.props.title.value || '...')+"\n                  ")])]):_c('span',[_vm._v("Select an answer")])]),_vm._v(" "),_vm._l((b.root.contentBlocks),function(branch,i){return _c('b-dropdown-item-button',{key:i,class:{
                  'decision-dropdown-btn-wrap': true,
                  'has-image': branch.image,
                },attrs:{"aria-labelledby":"DropdownMenu"},on:{"click":function($event){return _vm.moveToBranch(branch, b, b.parentId)}}},[_c('div',{staticClass:"image-wrap"},[(branch.image && branch.image.url)?_c('img',{attrs:{"src":_vm._f("cdn")(branch.image.url &&
                      (branch.image.url + '?width=30'))}}):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"label text-truncate"},[_vm._v("\n                  "+_vm._s(branch.props.title.value || '...')+"\n                ")])])})],2)]:_vm._l((b.root.contentBlocks),function(branch,i){return _c('div',{key:i,class:{
                'decision-button-wrap': true,
                'has-image': branch.image,
              }},[(branch.image)?_c('button',{staticClass:"answer-button",attrs:{"type":"button","autocomplete":"off","aria-pressed":b.answer === branch,"name":_vm._f("truncateText")(branch.props.title.value || '...',120)},on:{"click":function($event){return _vm.moveToBranch(branch, b, b.parentId)}}},[_c('div',{staticClass:"aspect aspect-4x3"},[_c('div',{staticClass:"image-wrap aspect-content"},[_c('img',{attrs:{"src":_vm._f("cdn")(branch.image.url)}}),_vm._v(" "),_vm._l((branch.image.markers),function(m,i){return _c('ImageMarker',{key:i,attrs:{"data":m}})})],2)]),_vm._v(" "),(branch.props.title.value)?_c('div',{staticClass:"label-wrap"},[_c('div',{staticClass:"radio-indicator"}),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n                    "+_vm._s(_vm._f("truncateText")(branch.props.title.value || '...',120))+"\n                  ")])]):_vm._e()]):_c('button',{staticClass:"answer-button btn btn-outline-primary",attrs:{"type":"button","autocomplete":"off","aria-pressed":b.answer === branch,"name":_vm._f("truncateText")(branch.props.title.value || '...',120)},on:{"click":function($event){return _vm.moveToBranch(branch, b, b.parentId)}}},[_c('div',{staticClass:"radio-indicator"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(branch.props.title.value || '...'))])])])})],2):_vm._e(),_vm._v(" "),(b.typeName === _vm.TYPE.PredProcess && b.extRef && b.extRef.item)?_c('div',{class:{
            'guide-link-fragments': _vm.showGuideLinkFragments.length > 1,
          }},[(_vm.checkIfPredProcessIsArticle(b))?[_c('Article',{attrs:{"parent-id":b.parentId,"item":b.extRef.item}})]:_vm._e(),_vm._v(" "),(_vm.showGuideLinkFragments.length > 1)?[_c('span',{staticClass:"process-btn-label"},[_vm._v("\n              "+_vm._s(_vm.showGuideLinkFragments[0])+"\n            ")]),_vm._v(" "),_c('button',{staticClass:"process-btn btn btn-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.showPredProcess(b)}}},[_vm._v("\n              "+_vm._s(_vm._f("unicodeToSymbol")(((b.extRef && b.extRef.title) || 'link')))+"\n            ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.showGuideLinkFragments[2]))])]:[_c('button',{staticClass:"btn btn-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.showPredProcess(b)}}},[_vm._v("\n              "+_vm._s(_vm.showGuideLinkFragments[0])+"\n            ")])]],2):_vm._e(),_vm._v(" "),(b.typeName === _vm.TYPE.ExtConnector)?_c('div',[_c('h2',{staticClass:"text-center"},[_vm._v("\n            "+_vm._s(b.extRef.title)+"\n          ")])]):_vm._e(),_vm._v(" "),(
            (b.typeName === _vm.TYPE.Process ||
              b.typeName === _vm.TYPE.PredProcess) &&
            _vm.stopOnProcess
          )?_c('div',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":b.isNextDisabled},on:{"click":function($event){return _vm.onProcessClickNext(b, b.parentId)}}},[_vm._v("\n            "+_vm._s(_vm.$t('buttons.step_next'))+"\n          ")])]):_vm._e(),_vm._v(" "),(b.typeName === _vm.TYPE.Terminator)?_c('div'):_vm._e(),_vm._v(" "),(b.notFound)?_c('div',[_c('span',{staticClass:"ss-alert text-warning"}),_vm._v("\n          "+_vm._s(_vm.$t('errors.flow_not_found'))+"\n        ")]):_c('div',{staticClass:"continue-indicator"})]):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }