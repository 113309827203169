<template>
  <div class="sp-b-steps">
    <steps-view-switcher :state.sync="stepsView" />
    <div class="sp-b-steps-container">
      <div v-show="stepsView === 'Slider'">
        <div
          ref="gallery-slider"
          class="swiper-container gallery-container"
          :data-swiper-id="'sp-swiper-' + b.key.toString(16)"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide sp-b-step"
              v-for="(step, index) in b.root.contentBlocks"
              :key="index"
            >
              <div class="slider-step-highlight">
                <template v-if="step.image.props.file.value">
                  <div class="markers-wrap show-image sp-b-img">
                    <span
                      :class="
                        'img-wrap image-' +
                        ((step.image.props.file.options.displaySize &&
                          step.image.props.file.options.displaySize.toLowerCase()) ||
                          'original')
                      "
                    >
                      <img
                        :src="step.image.props.file.value | cdn"
                        :alt="step.image.props.file.options.name"
                      />
                      <sp-image-marker
                        v-for="(marker, idx) in step.image.markers"
                        :key="idx"
                        :data="marker"
                        @click="
                          navigateSlide(
                            'sp-swiper-' + b.key.toString(16),
                            marker.action,
                          )
                        "
                      />
                    </span>
                    <magnifier
                      :glass-size="300"
                      :glass-style="{
                        border: '1px solid #1a1a1a',
                        borderRadius: '50%',
                      }"
                      :zoom-level="3"
                      :padding="25"
                    >
                      <template #content>
                        <span
                          :class="
                            'img-wrap image-' +
                            ((step.image.props.file.options.displaySize &&
                              step.image.props.file.options.displaySize.toLowerCase()) ||
                              'original')
                          "
                        >
                          <img
                            :src="step.image.props.file.value | cdn"
                            :alt="step.image.props.file.options.name"
                          />
                          <sp-image-marker
                            v-for="(marker, idx) in step.image.markers"
                            :key="idx"
                            :data="marker"
                            @click="
                              navigateSlide(
                                'sp-swiper-' + b.key.toString(16),
                                marker.action,
                              )
                            "
                          />
                        </span>
                      </template>
                    </magnifier>
                  </div>
                </template>
              </div>
              <div class="sp-b-steps-caption">
                <span class="d-block text-center steps-counter">{{
                  $t('guide.step_x_of_x', {
                    current: index + 1,
                    total: b.root.contentBlocks.length,
                  })
                }}</span>
                <span class="alt-steps-counter">
                  <span class="current-step">{{ index + 1 }}</span>
                  <span class="step-divider">/</span>
                  <span class="total-steps">{{
                    b.root.contentBlocks.length
                  }}</span>
                </span>
                <span class="alt-steps-counter-2">
                  <span class="current-step">{{ index + 1 }}</span>
                  <span class="step-divider">of</span>
                  <span class="total-steps">{{
                    b.root.contentBlocks.length
                  }}</span>
                </span>
                <h4
                  class="step-by-step-title"
                  v-html="step.props.title.value"
                />
                <p v-html="trimNonBrakingSpaces(step.text.html)" />
              </div>
            </div>
          </div>
          <div
            class="swiper-pagination"
            v-if="showSwiperPagination"
          >
            <span class="active" />
            <span class="total" />
          </div>
          <div
            v-if="showSwiperPagination"
            class="swiper-button-prev"
            slot="button-prev"
          />
          <div
            v-if="showSwiperPagination"
            class="swiper-button-next"
            slot="button-next"
          />
        </div>

        <div
          class="sp-b-steps-caption-static"
          :class="[
            { 'has-pagination': showSwiperPagination },
            { 'has-title': containTitle },
          ]"
        >
          <span
            class="d-block text-center steps-counter"
            v-if="showSwiperPagination"
            >{{
              $t('guide.step_x_of_x', {
                current: index + 1,
                total: b.root.contentBlocks.length,
              })
            }}</span
          >
          <span class="alt-steps-counter">
            <span class="current-step">{{ index + 1 }}</span>
            <span class="step-divider">/</span>
            <span class="total-steps">{{ b.root.contentBlocks.length }}</span>
          </span>
          <h4
            class="step-by-step-title"
            v-show="indexStep.props.title.value"
            v-html="indexStep.props.title.value"
          />
          <div
            class="step-text"
            v-html="trimNonBrakingSpaces(indexStep.text.html)"
          />
        </div>

        <div class="grouped-gallery-container">
          <grouped-slider-view :steps="b.root.contentBlocks" />
        </div>
      </div>

      <ol
        v-if="stepsView === 'List'"
        class="list-container"
      >
        <li
          class="sp-b-step-list"
          v-for="(step, index) in b.root.contentBlocks"
          :key="index"
        >
          <div class="sp-b-html">
            <h4
              v-show="step.props.title.value"
              v-html="step.props.title.value"
            />
            <div
              class="step-text"
              v-html="trimNonBrakingSpaces(step.text.html)"
            />
          </div>
          <div class="step-image">
            <template v-if="step.image.props.file.value">
              <div class="markers-wrap sp-b-img">
                <span class="img-wrap">
                  <img
                    :src="step.image.props.file.value | cdn"
                    :alt="step.image.props.file.options.name"
                  />
                  <sp-image-marker
                    v-for="(marker, idx) in step.image.markers"
                    :key="idx"
                    :data="marker"
                    @click="
                      navigateSlide(
                        'sp-swiper-' + b.key.toString(16),
                        marker.action,
                      )
                    "
                  />
                </span>
                <magnifier
                  :glass-size="80"
                  :glass-style="{
                    border: '1px solid #1a1a1a',
                    borderRadius: '50%',
                  }"
                  :zoom-level="3"
                  :padding="10"
                >
                  <template #content>
                    <span class="img-wrap">
                      <img
                        :src="step.image.props.file.value | cdn"
                        :alt="step.image.props.file.options.name"
                      />
                      <sp-image-marker
                        v-for="(marker, idx) in step.image.markers"
                        :key="idx"
                        :data="marker"
                        @click="
                          navigateSlide(
                            'sp-swiper-' + b.key.toString(16),
                            marker.action,
                          )
                        "
                      />
                    </span>
                  </template>
                </magnifier>
              </div>
            </template>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import SpImageMarker from './ImageMarker'
import StepsViewSwitcher from './StepsViewSwitcher'
import Magnifier from './magnifier/Magnifier.vue'
import GroupedSliderView from './slider/GroupedSliderView.vue'

export default {
  props: ['b'],
  components: {
    SpImageMarker,
    StepsViewSwitcher,
    Magnifier,
    GroupedSliderView,
  },
  data() {
    const initialStepsView =
      typeof CLIENT !== 'undefined' && CLIENT.stepsView
        ? CLIENT.stepsView
        : 'Slider'
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    return {
      stepsView: initialStepsView,
      index: 0,
      themeName,
    }
  },
  mounted() {
    const key = this.b.key.toString(16)
    this.attachSwiper(key)
  },
  computed: {
    steps() {
      return this.b.root.contentBlocks || []
    },
    indexStep() {
      return this.steps[this.index]
    },
    showSwiperPagination() {
      if (
        CLIENT &&
        this.themeName === 'o2' &&
        this.b.root.contentBlocks.length <= 1
      ) {
        return false
      }

      return true
    },
    containTitle() {
      if (
        CLIENT &&
        this.themeName === 'o2' &&
        this.indexStep.props.title.value.length
      ) {
        return true
      }

      return false
    },
  },
  methods: {
    navigateSlide(swiperKey, action) {
      const swiper = this.$el.querySelector(
        `[data-swiper-id=${swiperKey}]`,
      ).swiper
      if (action === 'prev') swiper.slidePrev()
      if (action === 'next') swiper.slideNext()
    },
    // renderSwiperPagination(swiper, current, total) {
    //   console.log(swiper.params)
    //   const params = swiper.params.pagination
    //   let paginationHTML = ''

    //   function renderBullets(params, current, total) {
    //     let html = ''
    //     for (let i = 0; i < total; i += 1) {
    //       html += `<${params.bulletElement} class="${params.bulletClass} ${
    //         current - 1 === i ? params.bulletActiveClass : ''
    //       }"></${params.bulletElement}>`
    //     }

    //     return html
    //   }
    //   return paginationHTML
    // },
    attachSwiper(key) {
      let swiper = this.$el.querySelector(`[data-swiper-id="sp-swiper-${key}"]`)

      if (swiper && swiper.swiper) {
        swiper.swiper.destroy(false, true)
      }
      swiper = new Swiper(swiper, {
        // To support 3.x version
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        // width: this.getSwiperWidth(swiper) || 700,
        noSwiping: false,
        slidesPerView: 'auto',
        centeredSlides: true,

        // 4.x version
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 1,
          clickable: true,
          // renderCustom: this.renderSwiperPagination,
        },
        roundLength: true,
        observer: true,
        observeParents: true,
      })

      swiper.on('slideChange', () => {
        this.index = swiper.activeIndex
      })
      swiper.on('reachEnd', () => {
        // Mark (.path-item) process element that SbS process finished
        const process = this.$refs['gallery-slider'].closest('.path-item')
        if (process !== null) {
          process.classList.add('sbs--process-finished')
        }
      })
    },
    trimNonBrakingSpaces(str) {
      if (!str) return ''
      return str.replace('&nbsp;', ' ')
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins';
@include sp-content-module {
  .sp-b-steps {
    position: relative;

    .sp-b-step {
      padding: 20px 30px;
      text-align: center;
    }

    .sp-b-step-title {
      margin: 1em 0 0.5em 0;
      text-align: center;
      font-weight: 600;
    }

    .sp-b-step-description {
      text-align: center;

      .pointer {
        font-weight: 600;
        cursor: pointer;
      }
    }

    .sp-b-step-note {
      text-align: center;
      font-size: 0.6875em;
    }

    .sp-b-steps-caption {
      text-align: $step-by-step-text-align;
      word-break: break-word;
    }

    ol.list-container {
      counter-reset: li;
      margin-left: 1em;

      > li {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin: 2.5em 0;
        counter-increment: li;

        &::before {
          content: counter(li);
          color: #fff;
          display: block;
          position: absolute;
          top: -16px;
          font-size: 12px;
          line-height: 20px;
          padding: 6px 0;
          font-weight: 500;
          background-color: $accent-color;
          border-radius: 14px;
          margin-left: -16px;
          text-align: center;
          width: 32px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .sp-b-html {
        flex: 1 0 50%;
      }

      .step-text {
        flex: 1 0 66%;
        border-top: 1px solid $border-color;
        padding-top: 2em;
        margin-right: 0.5em;
      }

      .step-image {
        flex: 0 1 auto;
        display: flex;
        margin-top: -16px;

        img {
          max-height: 400px;
        }

        .markers-wrap {
          display: flex;
          justify-content: center;
        }

        .img-wrap {
          overflow: hidden;
        }
      }
    }
    .swiper-slide:not(.swiper-slide-active) {
      .slider-step-highlight {
        .image-marker {
          animation: none;
          & > div {
            animation: none;
          }
        }
      }
    }
    .slider-step-highlight {
      .markers-wrap {
        display: flex;
        justify-content: center;
      }

      img {
        margin: 0 auto;
        max-height: 400px;
        max-width: 100%;
        display: inline;
      }
    }

    .alt-steps-counter,
    .alt-steps-counter-2 {
      display: none;
    }

    .sp-b-steps-caption-static {
      display: none;
      word-break: break-word;

      .step-text {
        text-align: $step-by-step-text-align;
      }
    }
  }

  .slider-step-highlight,
  .step-image {
    .magnifier-wrap {
      display: none;
    }
  }

  .grouped-gallery-container {
    display: none;
  }
}
</style>
