import { BFSIterative } from '~/utils'
import { ButtonAction } from '~/enums/content'
import {
  TYPE as CONTENT_BLOCK_TYPE,
  LimitedTextEditor,
  EnumEditor,
  ContentBlock,
  ContentBlockCollection,
  ContentBlockFactory,
  ButtonsContentBlock,
  PropertyEditor,
  AlignmentEditor,
} from '../custom-article/model'

export let TYPE
;(function (TYPE) {
  TYPE.Process = 'Process'
  TYPE.PredProcess = 'PredProcess'
  TYPE.Decision = 'Decision'
  TYPE.Branch = 'Branch'
  TYPE.Terminator = 'Terminator'
  TYPE.IntConnector = 'IntConnector'
  TYPE.ExtConnector = 'ExtConnector'
})(TYPE || (TYPE = {}))

export let COMMAND
;(function (COMMAND) {
  COMMAND[(COMMAND.Create = 1)] = 'Create'
  COMMAND[(COMMAND.Copy = 2)] = 'Copy'
  COMMAND[(COMMAND.Trash = 3)] = 'Trash'
  COMMAND[(COMMAND.Index = 4)] = 'Index'
  COMMAND[(COMMAND.Reference = 5)] = 'Reference'
  COMMAND[(COMMAND.Expand = 6)] = 'Expand'
  COMMAND[(COMMAND.Scroll = 7)] = 'Scroll'
  COMMAND[(COMMAND.SetImage = 8)] = 'SetImage'
  COMMAND[(COMMAND.Modify = 9)] = 'Modify'
})(COMMAND || (COMMAND = {}))

export let EDITOR
;(function (EDITOR) {
  EDITOR.Standard = 'Standard'
  EDITOR.Custom = 'Custom'
})(EDITOR || (EDITOR = {}))

export let BRANCH_VIEW
;(function (BRANCH_VIEW) {
  BRANCH_VIEW[(BRANCH_VIEW.Button = 1)] = 'Button'
  BRANCH_VIEW[(BRANCH_VIEW.Dropdown = 2)] = 'Dropdown'
})(BRANCH_VIEW || (BRANCH_VIEW = {}))

export let referencesMap = {}

export class BranchViewEditor extends EnumEditor {
  constructor(config) {
    const tKey = 'branch-view'
    const options = [
      { tKey: `${tKey}-buttons`, value: BRANCH_VIEW.Button.toString() },
      { tKey: `${tKey}-dropdown`, value: BRANCH_VIEW.Dropdown.toString() },
    ]
    super(config, options)
  }
}

export class ControlButtonsContentBlock extends ButtonsContentBlock {
  constructor(config) {
    super(config)
    this.icon = 'link'

    this._actions = [
      { key: 'df-next', type: ButtonAction.Next },
      { key: 'df-prev', type: ButtonAction.Prev },
      { key: 'df-restart', type: ButtonAction.Restart },
      ...this._actions,
    ]
  }
}

export class FlowBlock extends ContentBlock {
  constructor(config) {
    super(config)

    this.index = (config && config.index) || '1'
    this.blockEditor = EDITOR.Standard

    if (
      config &&
      config.props &&
      config.props.note &&
      config.props.note.value
    ) {
      this.props.note = new LimitedTextEditor({ ...config.props.note })
    }

    const captionIsInvisible =
      (config &&
        config.props &&
        config.props.caption &&
        config.props.caption.options.isInvisible) ||
      false

    this.props.caption.options.isInvisible = captionIsInvisible
    this._ignoredProps = ['color']
  }
}
export class ProcessFlowBlock extends FlowBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Process
    this.renderView = 'process'
    this.icon = 'process'
    this.color = 'yellow'

    const name =
      config && config.root && !config.isCopy
        ? config.root.name
        : 'group-' + this.key
    this.root = new FlowBlockCollection(
      (config && { ...config.root, isCopy: config.isCopy, name }) || { name },
    )
  }
}
export class PredProcessFlowBlock extends FlowBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.PredProcess
    this.renderView = 'predprocess'
    this.icon = 'predprocess'
    this.color = 'yellow'
    this.extRef = config.extRef
    this.buttonShow = new PropertyEditor({
      value: config.buttonShow?.value || 'Show',
    })
  }
}
export class IntConnectorFlowBlock extends FlowBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.IntConnector
    this.renderView = 'intconnector'
    this.icon = 'intconnector'
    this.hideHeader = true
    this.hideDescription = true
    this.hideInternalNote = true

    this.ref = config && config.ref
  }

  get terminated() {
    return !!this.ref
  }
}
export class ExtConnectorFlowBlock extends FlowBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.ExtConnector
    this.renderView = 'extconnector'
    this.icon = 'extconnector'
    this.color = 'blue'

    this.extRef = config.extRef
    this.start = config.start
  }

  get terminated() {
    return !!this.extRef
  }
}
export class TerminatorFlowBlock extends ProcessFlowBlock {
  constructor(config) {
    super(config)
    this.typeName = TYPE.Terminator
    this.renderView = 'terminator'
    this.icon = 'terminator'
    this.color = 'gray'
    this.terminated = true
  }
}
export class BranchFlowBlock extends FlowBlock {
  constructor(config) {
    super(config)

    this.typeName = TYPE.Branch
    this.renderView = 'branch'
    this.icon = 'branch'
    this.color = 'purple'
    this.blockEditor = EDITOR.Custom
    this.hideDescription = true

    const name =
      config && config.root && !config.isCopy
        ? config.root.name
        : 'branch-blocks-' + this.key
    const defaultRootConf = { name, isCopy: config && config.isCopy }
    const rootConf = { ...defaultRootConf, ...(config && config.root), name }
    this.root = new FlowBlockCollection(rootConf)

    this._rootManager = new FlowBlockCollectionManager(this.root)

    if (config && config.image) {
      this.image = config.image
    }
  }

  get terminated() {
    const last = this.root.getByIndex(this.root.count - 1)

    if (
      last instanceof IntConnectorFlowBlock ||
      last instanceof ExtConnectorFlowBlock ||
      last instanceof TerminatorFlowBlock ||
      last instanceof DecisionFlowBlock
    ) {
      return last.terminated
    }

    return false
  }
}
/**
 * The next type for branch flow block, was added to optimize saving articles with large content structure.
 * The following type is extended from the original Branch type, but with a reference to the entity content
 * in database, here 'root' property, that keep all branch content, is added to '_ignoredProps' on JSON.stringify()
 *
 * Task: https://worldmanuals.atlassian.net/browse/AGE-966
 */
export class BranchReferenceFlowBlock extends BranchFlowBlock {
  constructor(config) {
    const id = config.id || 0
    const root = config.root || referencesMap[id]
    super({ ...config, root })

    this.id = id

    this._ignoredProps = [...(this._ignoredProps || []), 'root']
  }

  toBranch() {
    return new BranchFlowBlock(this)
  }

  setRoot(config) {
    this.root = new FlowBlockCollection(config)
  }

  async getRoot() {
    // TODO
  }
}
export class DecisionFlowBlock extends FlowBlock {
  constructor(config) {
    super(config)

    this.typeName = 'Decision'
    this.renderView = 'decision'
    this.icon = 'decision'
    this.color = 'purple'

    const name =
      config && config.root && !config.isCopy
        ? config.root.name
        : 'decision-' + this.key
    const defaultRootConf = { name, isCopy: config && config.isCopy }
    const rootConf = { ...defaultRootConf, ...(config && config.root), name }
    this.root = new FlowBlockCollection(rootConf)

    this._rootManager = new BranchCollectionManager(this.root)

    const props = (config && config.props) || {
      branchView: { value: BRANCH_VIEW.Button },
      align: {},
    }
    this.props.branchView = new BranchViewEditor(props.branchView)
    this.props.align = new AlignmentEditor(props.align)
  }

  get terminated() {
    return !this.root.contentBlocks.find((b) => {
      return b instanceof BranchFlowBlock && !b.terminated
    })
  }

  toJSON() {
    const obj = super.toJSON()
    return {
      ...obj,
    }
  }
}

export class FlowBlockCollection extends ContentBlockCollection {
  constructor(defConfig) {
    const config = { factory: new FlowBlockFactory(), ...defConfig }
    super(config)
  }

  get count() {
    return this.contentBlocks.length
  }

  toJSON() {
    return {
      contentBlocks: this.contentBlocks,
      name: this.name,
    }
  }
}
export class FlowBlockCollectionManager {
  constructor(collection) {
    this.$collection = collection
    // Means that after these blocks could be other blocks
    this.$typesToFollow = [TYPE.Process, TYPE.PredProcess]
    this.$terminatorTypes = [
      TYPE.Decision,
      TYPE.IntConnector,
      TYPE.ExtConnector,
      TYPE.Terminator,
    ]
  }

  couldBeFollowed(type) {
    return this.$typesToFollow.indexOf(type) !== -1
  }

  canInsertInto(index, type) {
    if (index < 0 || index > this.$collection.count) {
      return false
    }

    const before = this.$collection.getByIndex(index - 1)
    if (before && !this.couldBeFollowed(before.type)) {
      return false
    }

    const after = this.$collection.getByIndex(index)
    if (after && !this.couldBeFollowed(type)) {
      return false
    }

    return (
      this.$typesToFollow.indexOf(type) > -1 ||
      this.$terminatorTypes.indexOf(type) > -1
    )
  }
}
export class BranchCollectionManager extends FlowBlockCollectionManager {
  canInsertInto(index, type) {
    if (index < 0 || index > this.$collection.count) {
      return false
    }

    return type === TYPE.Branch
  }
}
export class FlowBlockFactory {
  create(type, config, isCopy) {
    config = { ...config, isCopy }
    switch (type) {
      case TYPE.Process:
        return new ProcessFlowBlock(config)
      case TYPE.PredProcess:
        return new PredProcessFlowBlock(config)
      case TYPE.IntConnector:
        return new IntConnectorFlowBlock(config)
      case TYPE.ExtConnector:
        return new ExtConnectorFlowBlock(config)
      case TYPE.Terminator:
        return new TerminatorFlowBlock(config)
      case TYPE.Decision:
        return new DecisionFlowBlock(config)
      case TYPE.Branch:
        return new BranchReferenceFlowBlock(config)
      case CONTENT_BLOCK_TYPE.Button:
        return new ControlButtonsContentBlock(config)
      default:
        return new ContentBlockFactory().create(type, config, isCopy)
    }
  }
}

export class Flow {
  constructor(raw) {
    let config
    if (raw && typeof raw === 'string') {
      try {
        config = JSON.parse(raw)
      } catch (e) {
        config = { root: { name: 'root' } }
      }
    } else {
      config = raw || { root: { name: 'root' } }
    }

    if (!config.root) {
      config.root = { name: 'root' }
    }

    this.root = new FlowBlockCollection(config.root)
    this.referenceIds = config.referenceIds || []

    this._rootManager = new FlowBlockCollectionManager(this.root)
  }

  get terminated() {
    const last = this.root.getByIndex(this.root.count - 1)

    if (
      last instanceof IntConnectorFlowBlock ||
      last instanceof ExtConnectorFlowBlock ||
      last instanceof TerminatorFlowBlock ||
      last instanceof DecisionFlowBlock
    ) {
      return last.terminated
    }

    return false
  }

  toJSON() {
    const blocks = this.getBlocksByType(TYPE.Branch)
    const referenceIds = blocks.map((branch) => branch.id).filter((id) => id)

    return {
      root: this.root,
      referenceIds,
    }
  }

  getBlocksByType(type, subtreeRoot) {
    const output = []
    const problem = {
      getRoot: () => {
        return { root: subtreeRoot || this.root }
      },
      isGoal: (found) => {
        if (found && found.type === type) {
          output.push(found)
        }
        // Continue BFS
        return false
      },
      getSuccessors: (subtreeRoot) => {
        return subtreeRoot && subtreeRoot.root
          ? subtreeRoot.root.contentBlocks
          : []
      },
    }

    BFSIterative(problem)
    return output
  }

  static setReferences(arr) {
    const obj = arr.reduce((obj, b) => ({ ...obj, [Number(b.id)]: b.root && JSON.parse(b.root) }), {})
    referencesMap = { ...referencesMap, ...obj }
  }

  static getReferences() {
    return Object.values(referencesMap) || []
  }

  static getReferenceById(id) {
    return referencesMap[id]
  }

  static clearReferences() {
    referencesMap = {}
  }

  static filterUnloadedReferences(arr) {
    return arr.filter(id => !referencesMap[id])
  }
}
