var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp-navigation-map",attrs:{"id":_vm.getWrapId()}},[_c('div',{staticClass:"map",class:{'with-table-of-contents': _vm.showTableOfContents}},[_c('div',{staticClass:"inm-content"},[_c('h5',{staticClass:"subtitle"},[_vm._v("\n        "+_vm._s(_vm.activeScreen.props.title.value)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"inm-wrap"},[_c('div',{staticClass:"image-wrap"},[(_vm.activeScreen.props && _vm.activeScreen.props.file)?_c('div',{staticClass:"inner-img-wrap"},[_c('img',{attrs:{"src":_vm._f("cdn")(_vm.activeScreen.props.file.value)},on:{"click":function($event){return _vm.onScreenClick($event)}}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"markers-wrap"},_vm._l((_vm.activeScreen.markers),function(m,i){return _c('image-marker',{directives:[{name:"sp-marker-action",rawName:"v-sp-marker-action",value:({
                placement: 'auto',
                customClass: 'marker-popover',
                content: m.action && m.action.tooltip,
                html: true,
                container: _vm.getWrapId(),
                trigger: 'manual',
              }),expression:"{\n                placement: 'auto',\n                customClass: 'marker-popover',\n                content: m.action && m.action.tooltip,\n                html: true,\n                container: getWrapId(),\n                trigger: 'manual',\n              }"}],key:((_vm.activeScreen.key) + "-" + i),class:{
                'cursor-pointer': !!(m.action && m.action.type),
                'inactive' : _vm.allInactive && _vm.activeMarker != i
              },attrs:{"id":_vm.getMarkerId(i),"data":m,"index":i},on:{"click":function($event){return _vm.onMarkerClick($event, m.action)}},nativeOn:{"mouseenter":function($event){_vm.activeMarker=i; if (!(m.action && m.action.type)) { _vm.allInactive=true }},"mouseleave":function($event){_vm.activeMarker=null; _vm.allInactive=null}}})}),1)])]),_vm._v(" "),_c('div',{staticClass:"side-block"},_vm._l((_vm.activeScreen.markers),function(m,i){return _c('div',{key:((_vm.activeScreen.key) + "-" + i),staticClass:"explanation-block",class:{
            'active' : _vm.activeMarker == i,
            'hidden' : _vm.isHiddenTooltip(_vm.activeScreen, i),
            'inactive' : _vm.allInactive && _vm.activeMarker != i
          },attrs:{"id":_vm.getMarkerId(i),"data":m},domProps:{"innerHTML":_vm._s('<div class=\'circle-number\'><span>' + i + '</span></div>' + _vm.tooltipText(_vm.activeScreen, i))},on:{"mouseenter":function($event){_vm.activeMarker=i; _vm.allInactive=true},"mouseleave":function($event){_vm.activeMarker=null; _vm.allInactive=null}}})}),0),_vm._v(" "),(_vm.activeScreen.props.caption && _vm.activeScreen.props.caption.value)?_c('p',{staticClass:"text-center m-0 p-3"},[_vm._v("\n        "+_vm._s(_vm.activeScreen.props.caption.value)+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.showTableOfContents)?_c('div',{staticClass:"table-of-contents"},[_c('h5',{staticClass:"subtitle"},[_vm._v("\n        "+_vm._s(_vm.$t('article.table_of_contents'))+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"list-group map-index"},_vm._l((_vm.tableOfContentsItems),function(i,index){return _c('li',{key:index,staticClass:"list-group-item cursor-pointer",class:{
            'active sp-active-text': (index == 0 && _vm.activeScreenId == null) || _vm.activeScreenId == i.props.refId.value,
          },on:{"click":function($event){return _vm.navigateToTargetId($event, i.props.refId.value)}}},[_c('div',{staticClass:"text-break"},[_vm._v("\n            "+_vm._s(i.props.title.value || _vm.$t('article.no_title'))+"\n          ")])])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }