class Enum {}

Enum.prototype.keys = function () {
  return Object.keys(this)
}

Enum.prototype.getName = function (value) {
  return this.keys().find(x => this[x] === value || this[x] === '' + value)
}

Enum.prototype.hasValue = function (value) {
  return typeof this.getName(value) !== 'undefined'
}

// Immitates Enum type functionality, like: Enum.prop === Enum[prop].
// Modifies dest object, so the editor's autocomplete could work
// Put a few sources to accomplish inheritance by composition
Enum.prototype.makeEnum = function (dest, ...sources) {
  sources.forEach(source => {
    const names = Object.keys(source)
    const enumProperties = {}
    const hiddenProperties = {}
    names.forEach((x, i) => {
      const value = typeof source[x] !== 'undefined' ? source[x] : (i + 1)

      enumProperties[x] = {
        value,
        enumerable: true,
        writable: false
      }

      if (x != value) {
        hiddenProperties[value] = {
          value,
          enumerable: false,
          writable: false
        }
      }
    })

    Object.defineProperties(dest, enumProperties)
    Object.defineProperties(dest, hiddenProperties)

    Object.setPrototypeOf(dest, Enum.prototype)
  })
  Object.freeze(dest)
  return dest
}

const exported = new Enum()
Object.freeze(exported)

// Singleton, not intended to "extend" any classes
// Use the makeEnum method
export default exported
