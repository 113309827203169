<template>
  <div
    v-if="ratingEnabled || feedbackEnabled"
    role="form"
    aria-label="Feedback form"
    class="feedback-rating"
  >
    <template v-if="isRating && ratingEnabled">
      <h2>Please rate how helpful this article was</h2>
      <BaseStarRating
        :rating.sync="rating"
        :disable-click="disableClick"
        @change="sendRating"
      />
    </template>
    <template v-else-if="isFeedback && feedbackEnabled">
      <h2>
        <template v-if="ratingEnabled && feedbackEnabled">
          Thank you for your feedback
        </template>
        <template v-else>How can we improve your experience?</template>
      </h2>
      <div>
        <label class="d-block">
          Please tell us the reason for your rating
        </label>
        <textarea
          v-model="feedback"
          rows="5"
          placeholder="Start typing..."
        />
        <div>
          <button
            :disabled="feedback.length === 0"
            @click="sendFeedback"
          >
            Submit
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="isLastStep">
      <h2>Thank you for your feedback</h2>
    </template>
    <template v-else-if="isLastStepRating">
      <h2>Thank you for your feedback</h2>
    </template>
  </div>
</template>

<script>
import { createGuid } from '~/utils'
import BaseStarRating from '~/components/base/StarRating.vue'

export default {
  components: { BaseStarRating },
  props: {
    entityId: { type: Number, required: true },
    entityType: { type: Number, required: true },
    deviceId: { type: String, default: undefined },
    revisionId: { type: Number, default: undefined },
  },
  data() {
    return {
      ratingEnabled: true,
      feedbackEnabled: true,
      rating: -1,
      feedback: '',
      isRating: true,
      isFeedback: false,
      groupGuid: createGuid(),
      disableClick: false,
    }
  },
  computed: {
    isLastStep() {
      return (
        (this.feedbackEnabled &&
          !this.isFeedback &&
          this.ratingEnabled &&
          !this.isRating) ||
        (this.feedbackEnabled &&
          !this.isFeedback &&
          !this.ratingEnabled &&
          this.isRating)
      )
    },
    isLastStepRating() {
      return (
        !this.feedbackEnabled &&
        !this.isFeedback &&
        this.ratingEnabled &&
        !this.isRating
      )
    },
  },
  methods: {
    sendRating() {
      this.disableClick = true

      try {
        this.$api.postFeedback({
          entityId: this.entityId,
          entityType: this.entityType,
          deviceId: this.deviceId,
          revisionId: this.revisionId,
          groupGuid: this.groupGuid,
          rating: this.rating,
        })
        this.isRating = false

        if (this.feedbackEnabled) this.isFeedback = true
      } catch {}
    },
    sendFeedback() {
      try {
        if (this.feedback.trim().length) {
          this.$api.postFeedback({
            entityId: this.entityId,
            entityType: this.entityType,
            deviceId: this.deviceId,
            revisionId: this.revisionId,
            groupGuid: this.groupGuid,
            feedback: this.feedback,
          })
        }

        this.isFeedback = false
      } catch {}
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';

@include sp-content-module {
  .feedback-rating {
    display: none;
    margin: 20px 0;
    padding: 28px 16px;
    text-align: center;

    @media (min-width: 1024px) {
      padding: 40px 16px;
    }

    &:has(.star-rating) {
      h2 {
        margin-bottom: 20px;
        font-size: 18px;

        @media (min-width: 1024px) {
          margin-bottom: 24px;
          font-size: 20px;
        }
      }
    }

    h2 {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;

      &:only-child {
        margin-bottom: 0 !important;
      }

      @media (min-width: 1024px) {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 28px;
      }
    }

    label {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    textarea {
      width: 100%;
      max-width: 700px;
      margin-bottom: 16px;

      @media (min-width: 1024px) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
