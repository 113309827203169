<template>
  <div
    id="SpTable"
    class="sp-b-table"
  >
    <table :style="{width: tableWidth ? `${tableWidth}px` : 'auto'}">
      <tbody>
        <tr
          v-for="row in data.rows"
          :key="row"
        >
          <td
            v-for="(cell, i) in getRowSubset(data.table, row, data.columns)"
            :key="cell.key"
            v-html="cell.html"
            :style="{
              'background-color': cell.color,
              width: `${(data.columnWidth[i] >= 20 && data.columnWidth[i]) || 20}px`,
            }"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ['data'],
  computed: {
    tableWidth () {
      let total = 0
      this.data.columnWidth &&
        this.data.columnWidth.forEach((cell) => {
          const width = parseInt(cell, 10)
          if (!isNaN(width)) total += width
        })
      return total
    }
  },
  methods: {
    getRowSubset (cells, rowNumber, totalCols) {
      return cells.slice((rowNumber - 1) * totalCols, rowNumber * totalCols)
    }
  }
}
</script>
