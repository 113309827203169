<template>
  <content-module
    :render-type="renderType"
    :button-text="buttonText"
    :title="(advisor && advisor.title) || ''"
  >
    <div
      class="sp-advisor-widget"
      :class="widgetClass"
    >
      <div
        v-if="loading"
        class="loader-wrap"
      >
        <LoadingDots
          dots-color="'FFF'"
          v-if="isDotsLoader"
        />
        <loader v-else />
      </div>
      <div v-else-if="!loading && stages.length > 0">
        <preview
          v-if="advisorFlow === 'steps'"
          :id="advisor.id"
          :stages="stages"
          :options="options"
          :language="languageId"
          :hide-results="hideResults"
          :content-type="advisor.contentType"
          :journey="advisor"
        />
        <PreviewFlow
          v-if="advisorFlow === 'flow'"
          :id="advisor.id"
          :stages="stages"
          :options="options"
          :language="languageId"
          :hide-results="hideResults"
          :content-type="advisor.contentType"
          :journey="advisor"
        />
        <BASModal />
      </div>
    </div>
  </content-module>
</template>

<script>
import { ContentLang, ContentLangCode } from '~/enums'
import ContentModule from '~/components/ContentModule.vue'
import Loader from '~/components/Loader.vue'
import Preview from '~/components/sp-advisor/Preview.vue'
import PreviewFlow from '~/components/sp-advisor/advisor-flow/PreviewFlow'
import LoadingDots from '~/components/LoadingDots'
import BASModal from '~/components/BASModal.vue'

export default {
  components: {
    LoadingDots,
    ContentModule,
    Loader,
    Preview,
    PreviewFlow,
    BASModal
  },
  data () {
    const contentId = this.$attrs['content-id']
    const languageId = this.$attrs['language-id']
    const widgetClass = this.$attrs['widget-class']
    const hideResults = this.$attrs['hide-results']
    const options =
      typeof this.$attrs.options === 'string'
        ? JSON.parse(this.$attrs.options || '{}')
        : this.$attrs.options
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    const locale = typeof CLIENT !== 'undefined' ? CLIENT.locale : 'en-GB'
    // available advisor-flow options: steps, flow. Default is steps
    const advisorFlow =
      this.$attrs['advisor-flow'] || CLIENT?.advisorFlow || 'steps'
    const renderType = this.$attrs['render-type'] || (CLIENT?.launchWidgetButton && '2') || '1' // '1' as widget; '2' as float button
    const buttonText = this.$attrs['button-text'] || CLIENT?.launchWidgetButtonName

    return {
      locale,
      themeName,
      contentId,
      languageId,
      widgetClass,
      options,
      advisorFlow,
      loading: false,
      notFound: false,
      advisor: null,
      brands: [],
      specifications: [],
      hideResults,
      renderType,
      buttonText,
    }
  },
  async mounted () {
    this.loading = true
    try {
      const brandsResponse = await this.$api.getAdvisorBrandsById(
        this.contentId,
      )
      this.brands = brandsResponse && brandsResponse.data

      const advisorResponse = await this.$api.getAdvisorById(this.contentId)
      this.advisor = advisorResponse && advisorResponse.data

      if (this.advisor) {
        const language = ContentLang.getName(
          ContentLang[ContentLangCode.getName(ContentLangCode[this.locale])],
        )
        const specificationsResponse = await this.$api.getAdvisorSpecifications(
          this.advisor.deviceType,
          language,
        )
        this.specifications =
          specificationsResponse && specificationsResponse.data
      }

      this.$emit('loaded', this.advisor)
      this.loading = false
    } catch (e) {
      this.$emit('loaded', null)
      this.loading = false
      this.notFound = true
    } finally {
      this.isLoading = false
    }

    this.$root.$on('app::advisor::device::compare', (device) => {
      this.$emit('compare', device)
    })
    this.$root.$on('app::advisor::device::select', (device) => {
      this.$emit('select', device)
    })

    this.$root.$on('app::advisor::recommendations', (data) => {
      this.$emit('recommendations', data)
    })

    window.addEventListener('message', (e) => {
      const { name, model } = e.data
      if (name === 'REMOVE_DEVICE_FROM_COMPARE') {
        this.$root.$emit(
          'app::advisor::device::remove-device-from-comparison',
          model.device,
        )
      } else if (name === 'REMOVE_ALL_DEVICES_FROM_COMPARE') {
        this.$root.$emit(
          'app::advisor::device::remove-all-devices-from-comparison',
        )
      }
    })
  },
  computed: {
    isDotsLoader () {
      const spContentModule =
        document.querySelector('.sp-content-module').attributes
      if (!spContentModule['widget-class']) {
        return false
      }
      return (
        spContentModule['widget-class'].nodeValue.includes('ppf') ||
        spContentModule['widget-class'].nodeValue.includes('sp-retail')
      )
    },
    stages () {
      const stages = (this.advisor && this.advisor.stages) || []
      const filtered = stages.sort((a, b) => a.priority - b.priority)

      return filtered.map((x) => ({
        id: x.id,
        title: x.title,
        description: x.description,
        workNote: x.workNote,
        canSkip: x.canSkip,
        type: x.settings && x.settings.type,
        buttonText: x.settings.buttonText,
        multiple: x.typeSelection === 'Multiple' || x.typeSelection === 2,
        selectAll: {
          enabled: x.enabled,
          text: x.enabledDescription,
          state: false,
        },
        interrate: {
          enabled: x.interrate,
          title: x.interrateTitle,
          description: x.interrateDescription,
        },
        interval:
          x.settings && x.settings.type === 'interval' && x.parameters[0],
        infinityParams:
          x.settings && x.settings.type === 'infinity'
            ? x.parameters.map((x) => ({
              value: x.id,
              text: x.title,
              note: x.description,
            }))
            : [],
        intervalParams:
          x.settings &&
          x.settings.type === 'interval' &&
          x.parameters[0] &&
          x.parameters[0].settings.intervals
            ? x.parameters[0].settings.intervals.map((x) => ({
              value: x.value,
              text: x.label,
            }))
            : [],
        brandsParams: this.brands
          .filter(
            (brand) =>
              x.settings.brands && x.settings.brands.includes(brand.slug),
          )
          .map((x) => ({
            text: x.name,
            value: x.slug,
          })),
        specificationsParams: this.specifications
          .filter(
            (spec) =>
              x.settings.specificationSlugs &&
              x.settings.specificationSlugs.includes(spec.slug),
          )
          .map((x) => ({
            text: x.name,
            value: x.slug,
          })),
      }))
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .sp-advisor-widget {
    width: 100%;
    height: auto;
    position: relative;

    .loader-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 50vh;
    }
  }
}
</style>
