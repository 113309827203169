<template>
  <b-modal
    static
    :id="MODAL_ID"
    modal-class="sp-content-module device-search-modal"
    size="lg"
    @hidden="onModalHidden"
    @shown="onModalShown"
    hide-footer
  >
    <div class="search-device-form">
      <h1 class="text-center">
        {{ $t('search.device_title') }}
      </h1>
      <div
        @keydown.up.prevent
        @keydown.down.prevent
        @keyup.up.prevent="move('up')"
        @keyup.down.prevent="move('down')"
      >
        <div class="search-input-wrap">
          <input
            ref="searchInput"
            class="form-control search-input mt-4"
            v-model="searchDeviceInput"
            :placeholder="placeholder"
            :class="{ results: results.length || notFound }"
            required
          />
          <div
            v-if="isBusy"
            class="spinner-grow search-indicator text-primary"
          />
          <span class="icon-search ss-search" />
          <div class="floating-label">
            {{ $t('search.search_for_device') }}
          </div>
        </div>
        <div v-if="results.length">
          <ul
            ref="results"
            class="search-results-list"
          >
            <li
              v-for="(item, i) in results"
              :key="i"
              :disabled="item.isDisabled"
              @click="selectDevice(item)"
              class="search-results-item"
            >
              <img
                v-if="item.device.image"
                class="device-img"
                :src="(item.device.image + '?width=30&height=30') | cdn"
              />
              <span class="device-name">
                {{ item.name }}
              </span>
            </li>
          </ul>
        </div>
        <div
          v-else-if="notFound"
          class="search-results-list"
        >
          <span class="no-results">{{ $t('search.no_results') }}</span>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { debounce, escape } from 'underscore/modules'
import { GenericContentType } from '~/enums'
import { ContentModule, CloseCross } from '~/components'
export default {
  props: {
    device: { type: Object, default: () => null },
  },
  components: {
    ContentModule,
    CloseCross,
  },
  data() {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    return {
      searchRequest: null,
      internalCache: {},
      MODAL_ID: 'SpDeviceSearchModal',
      CONTENT_PREVIEW_MODAL: 'SpContentPreviewModal',
      searchDeviceInput: '',
      isBusy: false,
      notFound: false,
      results: [],
      themeName,
    }
  },
  computed: {
    cache: {
      get() {
        if (this.searchRequest) {
          const key = this.searchRequest.genericId
          const output = this.internalCache[key] || []
          return output.slice(0, 5)
        }
      },
      set(result) {
        if (this.searchRequest) {
          const key = this.searchRequest.genericId
          this.internalCache[key] = this.internalCache[key] || []
          this.internalCache[key].unshift(result)
        }
      },
    },
    placeholder() {
      return this.themeName === 'o2'
        ? this.$t('search.search_for_device_shorter')
        : this.$t('search.search_for_device')
    },
  },
  mounted() {
    // EVENTS
    this.$root.$on('app::deviceSearchModal::search', (searchRequest) => {
      // To save vue observer
      this.searchRequest = { ...this.searchRequest, ...searchRequest }

      if (this.device) {
        const { genericType, genericId, genericName } = this.searchRequest
        const query = this.device.name

        this.$api
          .searchGenericContentByDevice({
            genericType,
            genericId,
            query,
            genericName,
          })
          .then(
            (res) => {
              const response = res.data
              const result = response
                .map(this.convertToGenericData)
                .find((x) => !!x && x.device.id === this.device.id)

              if (result) {
                this.selectDevice(result)
                this.$root.$emit('bv::show::modal', this.CONTENT_PREVIEW_MODAL)
              } else {
                this.toggleModal(true)
              }
            },
            () => {
              this.toggleModal(true)
            },
          )
      } else {
        this.toggleModal(true)
      }
    })

    this.$root.$on('bv::modal::hidden', (event, modalId) => {
      modalId === this.MODAL_ID && this.onModalHidden()
    })
  },
  methods: {
    toggleModal(show) {
      if (show) {
        this.$root.$emit('bv::show::modal', this.MODAL_ID)
        this.results = []
      } else {
        this.$root.$emit('bv::hide::modal', this.MODAL_ID)
      }
    },
    onModalHidden() {
      // TODO refactor
      // const typeAheadElement = this.$modal('#' + this.MODAL_ID).find('.typeahead')
      // typeAheadElement.typeahead('val', '')\
      this.searchDeviceInput = ''
    },
    onModalShown() {
      this.$refs.searchInput.focus()
    },
    searchForDevice(query) {
      const { genericType, genericId, genericName } = this.searchRequest
      this.isBusy = true

      return this.$api
        .searchGenericContentByDevice({
          genericType,
          genericId,
          query,
          genericName,
        })
        .then((res) => {
          const response = res.data
          const result = response
            .map(this.convertToGenericData)
            .filter((x) => !!x)
          this.results = result
        })
        .finally(() => {
          this.isBusy = false
        })
    },
    selectDevice(item) {
      this.$root.$emit('app::deviceSearchModal::result', item)
    },
    filterActualDevices(list) {
      const slavesToShow = {}
      return list.filter((item) => {
        const device = item.device
        if (!device) return

        if (item.hasGenericInstruction || item.hasProblem) {
          if (!device.masterId && device.slaves && device.slaves[0]) {
            slavesToShow[device.slaves[0]] = true
            return false
          } else if (slavesToShow[device.id]) {
            return true
          } else if (!device.masterId) {
            return true
          }
        } else {
          if (slavesToShow[device.id]) {
            slavesToShow[device.id] = false
            return true
          }
        }
      })
    },

    convertToGenericData(item) {
      if (item.instructionInfo) {
        const info =
          typeof item.instructionInfo === 'string'
            ? JSON.parse(item.instructionInfo)
            : item.instructionInfo
        const topicId = info.chapterId || null
        const guideId = info.instuctionCmtReferenceId || null
        return {
          name: item.device.name,
          device: item.device,
          genericContentReference: {
            genericId: guideId,
            genericType: GenericContentType.Instruction,
          },
        }
      } else if (item.problemInfo) {
        const info =
          typeof item.problemInfo === 'string'
            ? JSON.parse(item.problemInfo)
            : item.problemInfo
        const topicId = info.chapterId || null
        const problemId = info.problemCmtReference || null
        return {
          name: item.device.name,
          device: item.device,
          genericContentReference: {
            genericId: problemId,
            genericType: GenericContentType.Problem,
          },
        }
      }
    },
    onDeviceSelected(result) {
      this.$root.$emit('app::deviceSearchModal::result', result)
    },
  },
  watch: {
    searchDeviceInput: debounce(function (input) {
      if (input) {
        this.isBusy = true
        this.searchForDevice(input).finally(() => {
          this.notFound = !this.results.length
          this.isBusy = false
        })
      } else {
        this.isBusy = false
        this.notFound = false
        this.results = []
        // this.$store.commit('search/clearDeviceSearchModalResult')
      }
    }, 500),
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .search-device-form {
    @include media-breakpoint-up('sm') {
      margin: 0 3em 2em;
    }
    h1 {
      color: $body-color;
    }
    .search-input-wrap {
      position: relative;
    }
    .search-input {
      padding-right: 2.5em;
      max-width: initial;
      box-shadow: none;

      &:focus {
        border: 1px solid $input-border-color;
        background-color: $white;
        box-shadow: none;
      }
      &.results {
        border-bottom-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $white;
      }
    }
    .floating-label {
      display: none;
    }

    .icon-search {
      position: absolute;
      font-size: 1.5em;
      top: 0;
      right: 0.5em;
    }
    .search-indicator {
      width: 1em;
      height: 1em;
      right: 2em;
      top: 0.3em;
    }
    .search-results-list {
      list-style: none;
      padding: 0;
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid $input-border-color;
      background-color: $white;
      box-shadow: none;
    }
    &.results {
      border-bottom-width: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $white;
    }
  }

  .icon-search,
  .search-indicator {
    position: absolute;
    font-size: 1.5em;
    top: 0.3em;
    right: 0.5em;
  }
  .search-indicator {
    width: 1em;
    height: 1em;
    right: 2em;
  }
  .search-results-list {
    list-style: none;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid $input-border-color;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
  .search-results-item {
    padding: 0.5em 0.6em;
    cursor: pointer;

    &:hover {
      background-color: $gray-100;
    }
  }
  .no-results {
    padding-left: map-get($spacers, 2);
  }
  .device-image {
    width: 30px;
    height: 30px;
  }
}
</style>
