<template>
  <div>
    <content-module
      :render-type="renderType"
      :button-text="buttonText"
      @init="setWidth"
    >
      <div
        ref="widget"
        class="sp-article-widget"
        :class="widgetClass"
      >
        <div v-if="!isLoading">
          <template v-if="!isNotFound && content">
            <h2
              v-if="!noTitle && !showArticleHeader"
              class="article-title"
            >
              {{ title }}
            </h2>
            <div
              class="article-header"
              v-if="showArticleHeader"
            >
              <button
                class="widget-refresh-button"
                @click="restartFlow()"
              >
                <span>Start again</span>
              </button>
              <h2>{{ title }}</h2>
            </div>
            <div
              class="article-description-block"
              v-if="showArticleDescription"
            >
              <div class="article-description-title">
                {{ $t('article.read_more_info') }}
                <button
                  @click="toggleDescription()"
                  class="article-close-description"
                  :class="{ active: hiddenDescription }"
                />
              </div>
              <div
                class="article-description-text"
                :class="{ hidden: hiddenDescription }"
              >
                {{ description }}
              </div>
            </div>
            <div
              :is="type"
              :content="content.html"
              :content-id="content.id"
              :revision-id="content.revisionId"
              :language="content.language"
              :restart-flow="restartFlowVal"
              :start-url="startUrl"
              :scroll-body="!showArticleHeader"
              v-bind="options"
              @track="track"
              @flow-terminated="(val) => (showFeedback = !!val)"
            />

            <Feedback
              v-if="showFeedback"
              :entity-id="content.id"
              :entity-type="1"
              :revision-id="content.revisionId"
            />
          </template>
          <template v-else>
            <p>{{ $t('errors.article_not_found') }}</p>
          </template>
        </div>
        <sp-loader
          v-else
          class="widget-loader"
        />

        <content-preview-modal @track="track" />
        <device-search-modal v-bind="options" />
        <BASModal />
      </div>
    </content-module>
  </div>
</template>
<script>
import {
  ContentModule,
  CustomArticle,
  DiagnosticFlow,
  NavigationMap,
  ContentPreviewModal,
  DeviceSearchModal,
  BASModal,
  Loader,
  Feedback,
} from '~/components'
import { ArticleType } from '~/enums'

export default {
  inheritAttrs: false,
  components: {
    ContentModule,
    CustomArticle,
    DiagnosticFlow,
    NavigationMap,
    ContentPreviewModal,
    DeviceSearchModal,
    BASModal,
    SpLoader: Loader,
    Feedback,
  },
  data() {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'

    const content = this.$attrs.content || null
    const noTitle = typeof this.$attrs['no-title'] !== 'undefined'
    const contentId = this.$attrs['content-id']
    const contentSlug = this.$attrs['content-slug']
    const widgetClass = this.$attrs['widget-class']
    const options = this.$attrs.options || {}
    const renderType = this.$attrs['render-type'] || '1' // '1' as widget; '2' as float button
    const showArticleHeader = !!(
      typeof CLIENT !== 'undefined' &&
      themeName === 'o2' &&
      contentId &&
      renderType === '2'
    )
    const showArticleDescription =
      typeof CLIENT !== 'undefined' && themeName === 'vodafone-pt' && contentId
    const startUrl = this.$attrs['start-url']
    const buttonText = this.$attrs['button-text']

    return {
      content,
      contentId,
      contentSlug,
      widgetClass,
      options,
      noTitle,
      isNotFound: false,
      isLoading: true,
      width: 0,
      showArticleHeader,
      showArticleDescription,
      restartFlowVal: false,
      hiddenDescription: false,
      startUrl,
      renderType,
      buttonText,
      showFeedback: false,
    }
  },
  computed: {
    type() {
      // TODO make a better mapping
      const articleTypeComponents = {
        [ArticleType.Article]: 'custom-article',
        [ArticleType.Diagnosticflow]: 'diagnostic-flow',
        [ArticleType.Interactivenavigationmap]: 'navigation-map',
      }

      return (
        this.content && articleTypeComponents[ArticleType[this.content.type]]
      )
    },
    title() {
      return this.content && this.content.title
    },
    description() {
      return this.content && this.content.description
    },
  },
  async mounted() {
    if (this.content) {
      this.isLoading = false
      this.$emit('loaded', this.content)
    } else if (this.contentId || this.contentSlug) {
      try {
        this.isLoading = true
        const request = this.contentSlug
          ? this.$api.getContentBySlug(this.contentSlug)
          : this.$api.getContentById(this.contentId)
        const res = await request.then((response) => {
          const data = response && response.data
          if (data && data.headRevision) {
            this.content = {
              type: ArticleType[data.contentType] || ArticleType.Article,
              html: data.headRevision.content.Html,
              title: data.headRevision.title,
              description: data.description,
              id: data.id,
              tags: data.tags || [],
              revisionId: data.headRevision.id,
              language: data.language,
            }

            this.showFeedback = this.content.type !== ArticleType.Diagnosticflow

            this.track({
              type: 'pageview',
              title: this.content.title,
            })

            if (this.$sampling) {
              const { id: articleId, contentType } = data
              const { id: revisionId } = data.headRevision
              this.$sampling.spSample(
                'article',
                articleId,
                revisionId,
                contentType,
              )
            }

            this.$emit('loaded', this.content)
          } else {
            this.isNotFound = true
            this.$emit('loaded', null)
          }
        })
      } catch (err) {
        this.isNotFound = true
        this.$emit('loaded', null)
        console.warn(err)
      } finally {
        this.isLoading = false
      }
    }
  },
  provide() {
    return {
      $modal: window.wmjQuery || (() => {}),
      getContainerWidth: () => {
        return this.width
      },
      getContainerBreakpoint: () => {
        const bps = {
          xs: 0,
          sm: 540,
          md: 720,
          lg: 960,
          xl: 1140,
        }

        const width = this.width || 0

        const breakpoint = Object.keys(bps)
          .reverse()
          .find((key) => width >= bps[key])
        return breakpoint
      },
    }
  },
  methods: {
    track({ type, title, action, category, label }) {
      if (this.$tracker) {
        switch (type) {
          case 'pageview':
            this.$tracker.page({ title })
            break
          case 'event':
            this.$tracker.event({
              action,
              category: category || this.content.title,
              label,
            })
        }
      }
    },
    restartFlow() {
      this.restartFlowVal = true
      setTimeout(() => {
        this.restartFlowVal = false
      }, 150)
    },
    toggleDescription() {
      this.hiddenDescription = !this.hiddenDescription
    },
    setWidth() {
      const el = this.$refs.widget
      this.width = el ? el.offsetWidth : 0
    },
  },
  watch: {
    isLoading(value) {
      this.$emit('isLoading', value)
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .sp-article-widget {
    width: 100%;
    height: auto;
    // max-height: 40vh;
    min-height: 100px;
    //overflow-y: auto;
    position: relative;

    .widget-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -30px;
      margin-top: -30px;
    }
  }
}
</style>
