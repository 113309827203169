import Enum from './Enum'

const ContentLang = {
  English: 1,
  Danish: 2,
  Spanish: 3,
  Czech: 4,
  Chilean: 5,
  Turkish: 6,
  German: 7,
  Italian: 8,
  Portuguese: 9,
  Swedish: 10,
  French: 11,
  Dutch: 12,
  DutchFormal: 13,
  Romanian: 14,
  Greek: 15,
  Hungarian: 16,
  LatinAmericanSpanish: 17,
  Norwegian: 18,
  Brazilian: 19,
  Arabic: 20,
}

Enum.makeEnum(ContentLang, ContentLang)

export default ContentLang
