<template>
  <div :class="['result-wrap', {mounted: isMounted}]">
    <template v-if="products.length > 0">
      <h1
        class="title"
        v-html="title"
      />
      <p class="description" v-html="model.description" />

      <div
        class="articles-wrap"
        :class="[{'two-devices': themeName === 'movistargroup' && products.length === 2}]"
      >
        <div
          v-for="(d, index) in products.slice(0, numbers)"
          :key="index"
          :class="['article-card', {'highest-score-article': index === 0}]"
          @click="handleSelect(d, index)"
        >
          <div class="header">
            <span v-if="headersLabelsList[index]">{{ headersLabelsList[index] }}</span>
          </div>

          <div class="image-wrap-article">
            <div :class="['article-icon-type', d.type]"></div>
          </div>

          <div class="name">
            {{ d.title }}
          </div>
          <div class="description">
            {{ d.description }}
          </div>
          <div>
            <button @click="showModal(d.id)" class="btn btn-show-article">{{ $t('advisor.btn_click-on-me') }}</button>
          </div>
        </div>
      </div>
      <article-preview-modal :content-id="activeId" @close="activeId = null" />
    </template>
    <template v-else>
      <div>
        <div class="bell" />
        <p
          class="text-no-matching"
          v-html="$t('advisor.text_no-matching')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ArticlePreviewModal from './ArticlePreviewModal.vue'
export default {
  data () {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    return {
      themeName: themeName || 'generic',
      isMounted: false,
      isWidget: false,
      MODAL_ID: 'SpAdvisorArticlePreviewModal',
      activeId: null
    }
  },
  components: { ArticlePreviewModal },
  props: {
    journey: { type: Object, default: () => ({}) },
    model: { type: Object, default: () => ({}) },
    products: { type: Array, default: () => [] },
    numbers: { type: Number, default: 3 },
    headers: { type: Array, default: () => [] },
    sessionId: { type: String, default: undefined },
  },
  mounted () {
    this.isMounted = true
    this.$el.scrollIntoView({ behavior: 'smooth' })
    this.$root.$emit('app::advisor::device::compare', this.products)
    this.$root.$emit('app::advisor::device::setDevices', this.products)
  },
  computed: {
    title () {
      const device = this.products[0] || {}
      const template = `<span class="device-name">${device.title}</span>`

      // {device-name} key are using in the old version of SP Advisor
      return this.model.title.replace('{device-name}', template).replace('{product-name}', template)
    },
    headersLabelsList () {
      return this.headers.length > 0
        ? this.headers
        : [
          this.$t('advisor.label_result-device-1'),
          this.$t('advisor.label_result-device-2'),
          this.$t('advisor.label_result-device-3')
        ]
    },
  },
  methods: {
    handleSelect (device, index) {
      this.$root.$emit('app::advisor::device::select', device)

      this.sampleBuyBtnClick(device.id, index)
    },
    showModal (id) {
      this.activeId = Number(id.replace('ARTICLE-', ''))
      this.$root.$emit('bv::show::modal', this.MODAL_ID)
    },
    sampleBuyBtnClick (productId, index) {
      const { id, contentType, language: languageId } = this.journey
      this.$sampling.spSample('advisorBuyButtonClick', id, contentType, languageId, this.sessionId, productId, index + 1)
    },
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .result-wrap {
    .title {
      .device-name {
        font-weight: 600;
      }
    }
    .articles-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .article-card {
        flex: 1;
        padding: 16px;
        text-align: center;
        border: 1px solid $dark;
        cursor: pointer;

        .header {
          margin-bottom: 8px;
        }

        .image-wrap-article {
          display: flex;
          justify-content: center;
          .article-icon-type {
            width: 46px;
            height: 46px;
            background-repeat: no-repeat;
            &.Article {
              background-image: url("data:image/svg+xml,%3Csvg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.1667 3.83337H11.8333C10.8167 3.83337 9.84165 4.23724 9.12276 4.95613C8.40387 5.67502 8 6.65004 8 7.66671V38.3334C8 39.35 8.40387 40.3251 9.12276 41.044C9.84165 41.7628 10.8167 42.1667 11.8333 42.1667H34.8333C35.85 42.1667 36.825 41.7628 37.5439 41.044C38.2628 40.3251 38.6667 39.35 38.6667 38.3334V15.3334M27.1667 3.83337L38.6667 15.3334M27.1667 3.83337V15.3334H38.6667M31 24.9167H15.6667M31 32.5834H15.6667M19.5 17.25H15.6667' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
            &.Diagnosticflow {
              background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 30C25 32.7614 27.2386 35 30 35C32.7614 35 35 32.7614 35 30C35 27.2386 32.7614 25 30 25C27.2386 25 25 27.2386 25 30ZM25 30C21.0218 30 17.2064 28.4196 14.3934 25.6066C11.5804 22.7936 10 18.9782 10 15M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15ZM10 15V35' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
            &.Interactivenavigationmap {
              background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9997 36.6667C29.2044 36.6667 36.6663 29.2048 36.6663 20C36.6663 10.7953 29.2044 3.33337 19.9997 3.33337C10.7949 3.33337 3.33301 10.7953 3.33301 20C3.33301 29.2048 10.7949 36.6667 19.9997 36.6667Z' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.9997 30C25.5225 30 29.9997 25.5229 29.9997 20C29.9997 14.4772 25.5225 10 19.9997 10C14.4768 10 9.99967 14.4772 9.99967 20C9.99967 25.5229 14.4768 30 19.9997 30Z' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.9997 23.3334C21.8406 23.3334 23.333 21.841 23.333 20C23.333 18.1591 21.8406 16.6667 19.9997 16.6667C18.1587 16.6667 16.6663 18.1591 16.6663 20C16.6663 21.841 18.1587 23.3334 19.9997 23.3334Z' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
            &.Promo {
              background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_46590_28828)'%3E%3Cpath d='M4.375 19.8625V14.35C4.375 13.7875 4.8375 13.325 5.4 13.325H17.4625C17.8 13.325 18.1375 13.2375 18.4375 13.0625L28.5625 7.13745C28.5625 7.13745 29.9 6.52495 30.8125 7.14995C31.55 7.66245 31.975 8.44995 31.5375 10.275C30.9875 12.525 28.9625 16.3375 31.625 25.6375C31.625 25.6375 32.3875 28.0375 30.75 28.725C29.1125 29.4125 27.575 28.1 27.575 28.1L18.7 21.775C18.3125 21.5 17.85 21.35 17.375 21.3375L5.6 21.1C4.925 21.0875 4.3875 20.5375 4.3875 19.8625H4.375Z' stroke='%23333333' stroke-miterlimit='10'/%3E%3Cpath d='M32.6377 15.525C32.6377 15.525 35.4627 15.5875 35.4627 18.0375C35.4627 20.4875 32.6377 20.3875 32.6377 20.3875' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M7.41211 23.7625L9.07461 31.7875C9.08711 31.8625 9.16211 31.925 9.23711 31.925H14.6246C14.7246 31.925 14.7996 31.8375 14.7871 31.7375L14.0371 23.7625' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_46590_28828'%3E%3Crect width='32.3375' height='26.3' fill='white' transform='translate(3.75 6.25)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }
          }
        }
        &.highest-score-article {
          .image-wrap-article {
            .article-icon-type {
              &.Article {
                background-image: url("data:image/svg+xml,%3Csvg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.1667 3.83337H11.8333C10.8167 3.83337 9.84165 4.23724 9.12276 4.95613C8.40387 5.67502 8 6.65004 8 7.66671V38.3334C8 39.35 8.40387 40.3251 9.12276 41.044C9.84165 41.7628 10.8167 42.1667 11.8333 42.1667H34.8333C35.85 42.1667 36.825 41.7628 37.5439 41.044C38.2628 40.3251 38.6667 39.35 38.6667 38.3334V15.3334M27.1667 3.83337L38.6667 15.3334M27.1667 3.83337V15.3334H38.6667M31 24.9167H15.6667M31 32.5834H15.6667M19.5 17.25H15.6667' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              }
              &.Diagnosticflow {
                background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 30C25 32.7614 27.2386 35 30 35C32.7614 35 35 32.7614 35 30C35 27.2386 32.7614 25 30 25C27.2386 25 25 27.2386 25 30ZM25 30C21.0218 30 17.2064 28.4196 14.3934 25.6066C11.5804 22.7936 10 18.9782 10 15M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15ZM10 15V35' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              }
              &.Interactivenavigationmap {
                background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9997 36.6667C29.2044 36.6667 36.6663 29.2048 36.6663 20C36.6663 10.7953 29.2044 3.33337 19.9997 3.33337C10.7949 3.33337 3.33301 10.7953 3.33301 20C3.33301 29.2048 10.7949 36.6667 19.9997 36.6667Z' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.9997 30C25.5225 30 29.9997 25.5229 29.9997 20C29.9997 14.4772 25.5225 10 19.9997 10C14.4768 10 9.99967 14.4772 9.99967 20C9.99967 25.5229 14.4768 30 19.9997 30Z' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.9997 23.3334C21.8406 23.3334 23.333 21.841 23.333 20C23.333 18.1591 21.8406 16.6667 19.9997 16.6667C18.1587 16.6667 16.6663 18.1591 16.6663 20C16.6663 21.841 18.1587 23.3334 19.9997 23.3334Z' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              }
              &.Promo {
                background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_46590_28828)'%3E%3Cpath d='M4.375 19.8625V14.35C4.375 13.7875 4.8375 13.325 5.4 13.325H17.4625C17.8 13.325 18.1375 13.2375 18.4375 13.0625L28.5625 7.13745C28.5625 7.13745 29.9 6.52495 30.8125 7.14995C31.55 7.66245 31.975 8.44995 31.5375 10.275C30.9875 12.525 28.9625 16.3375 31.625 25.6375C31.625 25.6375 32.3875 28.0375 30.75 28.725C29.1125 29.4125 27.575 28.1 27.575 28.1L18.7 21.775C18.3125 21.5 17.85 21.35 17.375 21.3375L5.6 21.1C4.925 21.0875 4.3875 20.5375 4.3875 19.8625H4.375Z' stroke='%23ffffff' stroke-miterlimit='10'/%3E%3Cpath d='M32.6377 15.525C32.6377 15.525 35.4627 15.5875 35.4627 18.0375C35.4627 20.4875 32.6377 20.3875 32.6377 20.3875' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M7.41211 23.7625L9.07461 31.7875C9.08711 31.8625 9.16211 31.925 9.23711 31.925H14.6246C14.7246 31.925 14.7996 31.8375 14.7871 31.7375L14.0371 23.7625' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_46590_28828'%3E%3Crect width='32.3375' height='26.3' fill='white' transform='translate(3.75 6.25)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
              }
            }
          }
        }

        .btn-show-article {
          background-color: $primary;
          color: white;
          margin-top: 24px;
        }

        .btn-buy-device {
          display: none;
        }

        .name {
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
