var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"magnifierGlass",staticClass:"magnifier-glass",style:(Object.assign({}, _vm.glassStyle,
    {width: _vm.size + 'px',
    height: _vm.size + 'px',
    top: _vm.y + 'px',
    left: _vm.x + 'px'}))},[_c('div',{style:({
      zoom: _vm.zoom,
      transform: ("\n          translateX(" + (-1 * (_vm.x + _vm.size / 2) + _vm.size / (2 * _vm.zoom) + _vm.padding) + "px)\n          translateY(" + (-1 * (_vm.y + _vm.size / 2) + _vm.size / (2 * _vm.zoom) + _vm.padding) + "px)\n      "),
    }),domProps:{"innerHTML":_vm._s(_vm.innerHtml)}})])}
var staticRenderFns = []

export { render, staticRenderFns }