import VueGtag from 'vue-gtag'

export default {
  install (Vue, options) {
    let tracker
    options = options || {}
    switch (options.trackerType) {
      case 'gtag':
        if (typeof Vue.$gtag === 'undefined' && options.gaCode) {
          Vue.use(VueGtag, {
            config: {
              id: options.gaCode,
              params: { send_page_view: false },
            },
          })
        }
        tracker = new GTagTracker(Vue, options.dimensions)
        break
      case 'globalGA':
        tracker = new GlobalGATracker(options.trackerName, options.dimensions)
        break
      case 'none':
      case undefined:
      default:
        tracker = new GenericTracker()
    }

    Vue.prototype.$tracker = tracker
  },
}

function GenericTracker () {
  this.emit = (event, data) => {
    console.log('Tracker event: ' + event, data)
  }
}

GenericTracker.prototype.page = function (data) {
  this.emit('pageview', data)
}
GenericTracker.prototype.event = function (data) {
  this.emit('event', data)
}

function GTagTracker (Vue, dimensions) {
  this.Vue = Vue
  this.$dimensions = dimensions || {}
}

GTagTracker.prototype.page = function ({ title, dimensions }) {
  dimensions = dimensions || {}
  this.Vue.$gtag &&
    this.Vue.$gtag.pageview({
      page_title: title,
      ...this.$dimensions,
      ...dimensions,
    })
}

GTagTracker.prototype.event = function ({
  action,
  category,
  label,
  dimensions,
}) {
  dimensions = dimensions || {}
  this.Vue.$gtag &&
    this.Vue.$gtag.event(action, {
      event_category: category,
      event_label: label,
      ...this.$dimensions,
      ...dimensions,
      // dimension1: 'SP Content Embedded',
    })
}

function GlobalGATracker (trackerName, dimensions) {
  this.$dimensions = dimensions || {}
  this.$onTrackerReady = (callback) => {
    if (typeof ga !== 'undefined') {
      ga(() => {
        const trackers = trackerName ? [ga.getByName(trackerName)] : ga.getAll()
        trackers.map((x) => x && callback && callback.call(this, x))
      })
    }
  }
}

GlobalGATracker.prototype.page = function ({ title, dimensions }) {
  dimensions = dimensions || {}
  this.$onTrackerReady(function (tracker) {
    tracker.set({
      ...this.$dimensions,
      ...dimensions,
    })
    tracker.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: title || document.title,
      location: window.location.href,
    })
  })
}

GlobalGATracker.prototype.event = function ({
  action,
  category,
  label,
  dimensions,
}) {
  dimensions = dimensions || {}
  this.$onTrackerReady(function (tracker) {
    tracker.set({
      ...this.$dimensions,
      ...dimensions,
    })
    tracker.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    })
  })
}
