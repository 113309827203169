<template>
  <div
    v-if="isLoading"
    class="loading-wrap"
  >
    <SpLoader />
  </div>
  <div
    v-else
    class="custom-article-render"
  >
    <h3>{{ contentItem.title.replace(/ *\[[^\]]*]/, '') }}</h3>
    <CustomArticle
      ref="article"
      :content="contentItem.content"
    />
  </div>
</template>

<script>
import { CustomArticle, Loader } from '~/components'

export default {
  props: ['item', 'parentId'],
  components: { CustomArticle, SpLoader: Loader },
  data() {
    return {
      contentQueue: [],
      isNotFound: false,
      isLoading: true,
    }
  },
  mounted() {
    this.getArticle()
  },
  computed: {
    contentItem() {
      return this.contentQueue[0]
    },
    contentType() {
      return this.contentItem && this.contentItem.type
    },
  },
  methods: {
    getArticle() {
      const type =
        this.item.type || (this.item.articleState && this.item.id && 'Article')

      const resp = (
        this.parentId
          ? this.$api.getRelatedContentById(this.parentId, this.item.id)
          : this.$api.getContentById(this.item.id)
      )
        .then(
          (res) => {
            try {
              const response = res.data
              const content = JSON.parse(response.headRevision.content.Html)
              this.addContentToQueue({
                type,
                ...response,
                content,
              })
            } catch (e) {
              console.error('Missing or unavailable article. ID:', item.id)
              this.isNotFound = true
            }
          },
          (e) => {
            this.isNotFound = true
            this.addContentToQueue()
          },
        )
        .finally(() => (this.isLoading = false))
    },
    addContentToQueue(contentItem) {
      this.contentQueue.unshift(contentItem)
      this.checkForNotFound()
    },
    checkForNotFound() {
      if (this.contentType) {
        this.isNotFound = false
      } else {
        this.isNotFound = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .loading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 60vh;
  }
}
</style>
